import React from 'react';
import { Input } from 'antd';
import CheckMarkIcon from 'components/Shared/Icons/CheckMarkIcon';

import styles from './style.module.scss';

const InputWithCheckmarkValidation = ({
  onChange,
  isValid,
  appearance = null,
  isDisabled = false,
  placeholder = null,
  testId = null,
}) => {
  return (
    <Input
      onChange={onChange}
      suffix={isValid ? <CheckMarkIcon /> : <span />}
      className={appearance && styles[appearance]}
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
      disabled={isDisabled}
      placeholder={placeholder}
      data-testid={testId}
    />
  );
};

export default InputWithCheckmarkValidation;
