import React, { useEffect, useState } from 'react';
import useAmplitude from 'utils/hooks/useAmplitude';
import ExtendedModal from 'components/Shared/Modal/index.js';
import WelcomeModal from 'components/Shared/WelcomeModal';
import { useFeatureFlag } from 'components/Providers/FeatureFlag';
import CorporateClientForm from 'components/Shared/CorporateClient/CorporateClientForm';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import useRegisterCorporationModal from './useRegisterCorporationModal';
import RegisterCorporationFormHeader from './RegisterCorporationFormHeader';
import CorporateClientRegistrationFormV2 from './CorporateClientRegistrationFormV2';
import CreditAccountCorporationRegistration from './CreditAccountCorporationRegistration';

import styles from './style.module.scss';

const RegisterCorporationModal = () => {
  const {
    isMobile,
    isRegisterCorporationLoading,
    isRegisterCorporationSuccess,
    isRegisterCreditAccountCorporationLoading,
    isRegisterCreditAccountCorporationSuccess,
    prospectUser,
    onRegister,
    onCreditAccountCorporationRegister,
  } = useRegisterCorporationModal();

  const [isWelcomeModalVisible, setIsWelcomeModalVisible] = useState(true);
  const [countryFromUrlParam, setCountryFromUrlParam] = useState(''); // ToDO: remove this state variable and use the one from the store when the query to get the country is implemented

  const isRegistrationSuccess =
    isRegisterCorporationSuccess || isRegisterCreditAccountCorporationSuccess;

  const { sendAmplitudeEvent } = useAmplitude();
  const { showFeature } = useFeatureFlag();

  const getQueryParameter = param => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  };

  useEffect(() => {
    setCountryFromUrlParam(getQueryParameter('country'));
  }, []);

  const renderContent = () => {
    if (!showFeature('selfRegistrationV2')) {
      return (
        <>
          <RegisterCorporationFormHeader />
          <CorporateClientForm
            isMobile={isMobile}
            isLoading={isRegisterCorporationLoading}
            isRegister={true}
            onSubmit={onRegister}
          />
        </>
      );
    }
    return countryFromUrlParam === 'GB' ? (
      <CreditAccountCorporationRegistration
        onSubmit={onCreditAccountCorporationRegister}
        isLoading={isRegisterCreditAccountCorporationLoading}
      />
    ) : (
      <>
        <RegisterCorporationFormHeader />
        <CorporateClientRegistrationFormV2
          isMobile={isMobile}
          isLoading={isRegisterCorporationLoading}
          onSubmit={onRegister}
        />
      </>
    );
  };

  const modalPadding = () => {
    if (!showFeature('selfRegistrationV2')) return null;
    if (countryFromUrlParam === 'GB') return styles.modalPadding_registrationInfo;
    return styles.modalPadding_registrationForm;
  };

  useEffect(() => {
    prospectUser &&
      sendAmplitudeEvent(
        AMPLITUDE_EVENT_TYPES.selfRegistrationCorporateClientRegistrationPageViewed,
      );
  }, [prospectUser, sendAmplitudeEvent]);

  const RegisterCorporationFormModal = () => (
    <ExtendedModal
      visible={prospectUser}
      closable={false}
      footer={null}
      wrapClassName={'modalWrapper'}
      width={countryFromUrlParam ? 565 : 630}
      className={modalPadding()}
    >
      {renderContent()}
    </ExtendedModal>
  );

  return (
    <>
      {RegisterCorporationFormModal()}
      {isRegistrationSuccess && !isMobile && (
        <WelcomeModal isVisible={isWelcomeModalVisible} setIsVisible={setIsWelcomeModalVisible} />
      )}
    </>
  );
};

export default RegisterCorporationModal;
