import React from 'react';

const ExitIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon /Exit">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.6445 5.71625L25.6296 6.66011C25.3297 6.69483 25.2066 7.05786 25.4181 7.26936L27.8172 9.66847L17.9051 19.5806C17.7662 19.7195 17.7662 19.9468 17.9051 20.0857L19.6729 21.8534C19.8118 21.9923 20.0391 21.9923 20.1779 21.8534L30.0901 11.9413L32.4892 14.3404C32.7007 14.5519 33.0669 14.4257 33.0984 14.1289L34.0423 6.114C34.0489 6.06019 34.0431 6.00559 34.0254 5.95435C34.0077 5.90311 33.9786 5.85656 33.9403 5.81823C33.902 5.7799 33.8554 5.7508 33.8042 5.73313C33.7529 5.71546 33.6984 5.70969 33.6445 5.71625ZM6.62305 12.5003C6.62305 12.017 7.0148 11.6253 7.49805 11.6253H17.498V8.37527H7.49805C5.21987 8.37527 3.37305 10.2221 3.37305 12.5003V32.5003C3.37305 34.7784 5.21987 36.6253 7.49805 36.6253H27.498C29.7762 36.6253 31.623 34.7784 31.623 32.5003V22.5003H28.373V32.5003C28.373 32.9835 27.9813 33.3753 27.498 33.3753H7.49805C7.0148 33.3753 6.62305 32.9835 6.62305 32.5003V12.5003Z"
        fill="#333333"
      />
    </g>
  </svg>
);

export default ExitIcon;
