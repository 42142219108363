const MenuLeftCreditAccountIcon = ({ color = '#318145' }) => (
  <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon /Wallet 3">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4.27692C0 2.57758 1.37759 1.2 3.07692 1.2H12.9231C14.6224 1.2 16 2.57758 16 4.27692V11.6615C16 13.3609 14.6224 14.7385 12.9231 14.7385H3.07692C1.37759 14.7385 0 13.3609 0 11.6615V4.27692ZM1.23077 9.2V11.6615C1.23077 12.6811 2.05732 13.5077 3.07692 13.5077H12.9231C13.9427 13.5077 14.7692 12.6811 14.7692 11.6615V9.2C14.7692 8.86013 14.4937 8.58461 14.1538 8.58461H10.3623C9.87594 9.37773 9.00044 9.90823 8 9.90823C6.99956 9.90823 6.12406 9.37773 5.63772 8.58461H1.84615C1.50629 8.58461 1.23077 8.86013 1.23077 9.2ZM14.7692 7.45889C14.5768 7.39086 14.3696 7.35384 14.1538 7.35384H10.07C9.76744 7.35384 9.5127 7.53519 9.3967 7.78528C9.15179 8.31331 8.61779 8.67746 8 8.67746C7.38221 8.67746 6.84821 8.31331 6.6033 7.78528C6.4873 7.53519 6.23256 7.35384 5.93005 7.35384H1.84615C1.63038 7.35384 1.42325 7.39086 1.23077 7.45889V7.35384C1.23077 6.33424 2.05732 5.50769 3.07692 5.50769H12.9231C13.9427 5.50769 14.7692 6.33424 14.7692 7.35384V7.45889ZM14.7692 4.27692V4.89208C14.255 4.50581 13.6158 4.27692 12.9231 4.27692H3.07692C2.38424 4.27692 1.74502 4.50581 1.23077 4.89208V4.27692C1.23077 3.25732 2.05732 2.43077 3.07692 2.43077H12.9231C13.9427 2.43077 14.7692 3.25732 14.7692 4.27692Z"
        fill={color}
      />
    </g>
  </svg>
);

export default MenuLeftCreditAccountIcon;
