import { Row, Typography } from 'antd';
import useTranslation from 'utils/hooks/useTranslation';
import { SuccessIcon } from 'components/Shared/Icons';
import { BritishGas, Engie, Whirlpool } from 'components/Shared/Icons/TrustedBusinesses';

import styles from './style.module.scss';

const { Title } = Typography;

const RegistrationMarketing = () => {
  const { translateText } = useTranslation();

  const marketingListTexts = [
    translateText('corporateAccounts.selfRegistration.marketing.businessBenefits.one'),
    translateText('corporateAccounts.selfRegistration.marketing.businessBenefits.two'),
    translateText('corporateAccounts.selfRegistration.marketing.businessBenefits.three'),
  ];

  const MarketingList = () => (
    <section>
      {marketingListTexts.map((text, i) => (
        <MarketingListItem key={i}>{text}</MarketingListItem>
      ))}
    </section>
  );

  const MarketingListItem = ({ children }) => (
    <Row className={styles.marketingListRow}>
      <span className={styles.marketingListCheckmarkWrapper}>
        <SuccessIcon />
      </span>
      <Typography>
        <p>{children}</p>
      </Typography>
    </Row>
  );

  const TrustedBusinesses = () => (
    <section id={styles.trustedBusinesses}>
      <Title level={5} type="secondary" className={styles.subtitle}>
        {translateText('corporateAccounts.selfRegistration.marketing.businessesTrust')}
      </Title>
      <section id={styles.trustedBusinessesIcons} data-testid="trustedBusinesses">
        <Whirlpool />
        <BritishGas />
        <Engie />
      </section>
    </section>
  );

  return (
    <section id={styles.marketing} className={styles.content} data-testid="marketing">
      <Title level={3} className={styles.title}>
        {translateText('corporateAccounts.selfRegistration.marketing.title')}
      </Title>
      <MarketingList />
      <TrustedBusinesses />
    </section>
  );
};

export default RegistrationMarketing;
