import { CREDIT_ACCOUNT_MIGRATION_STATUS, CREDIT_ACCOUNT_STATUS } from 'constants/creditAccount';

const toCamelCase = string => string?.charAt(0).toLowerCase() + string?.slice(1);

const creditAccountMigrationConfigManager = ({ corporation, override = null }) => {
  if (!corporation) return null;

  const creditAccountMigrationStatus = toCamelCase(corporation.creditAccountMigrationStatus);
  const creditAccountStatus = toCamelCase(corporation.creditAccountStatus);

  const isStatus = (status, expected) => status === expected;
  const isAnyStatus = (status, expectedStatuses) => expectedStatuses.includes(status);

  const isCreditAccountMigrationNew = isStatus(
    creditAccountMigrationStatus,
    CREDIT_ACCOUNT_MIGRATION_STATUS.NEW,
  );
  const isCreditAccountMigrationStarted = isStatus(
    creditAccountMigrationStatus,
    CREDIT_ACCOUNT_MIGRATION_STATUS.STARTED,
  );

  const isCreditAccountMigrationNotStarted =
    !creditAccountMigrationStatus ||
    creditAccountMigrationStatus === CREDIT_ACCOUNT_MIGRATION_STATUS.NOT_STARTED;

  const isCreditAccountStatusActive = isStatus(creditAccountStatus, CREDIT_ACCOUNT_STATUS.ACTIVE);
  const isCreditAccountStatusPending = isStatus(creditAccountStatus, CREDIT_ACCOUNT_STATUS.PENDING);
  const isCreditAccountStatusRejected = isStatus(
    creditAccountStatus,
    CREDIT_ACCOUNT_STATUS.REJECTED,
  );

  const isNewBadgeDisplayed =
    isCreditAccountMigrationStarted && (isCreditAccountStatusPending || !creditAccountStatus);
  const isCreditAccountPageBlocked =
    !isCreditAccountMigrationStarted && !isCreditAccountMigrationNew;
  const isInvoicesPageBlocked =
    isCreditAccountMigrationNotStarted ||
    isAnyStatus(creditAccountStatus, [
      CREDIT_ACCOUNT_STATUS.CLOSED,
      CREDIT_ACCOUNT_STATUS.REJECTED,
    ]);
  const isCreditAccountPendingBannerVisible = isCreditAccountStatusPending;
  const isCreditAccountRejectedBannerVisible = isCreditAccountStatusRejected;
  const isCreditAccountBannerVisible = isCreditAccountMigrationStarted;
  const isCreditAccountInfoModalVisible =
    override?.isCreditAccountInfoModalVisible ??
    (isCreditAccountMigrationStarted && !creditAccountStatus);
  const isPaymentMethodsPageBlocked = isCreditAccountStatusActive;
  const isBillingPageReadonly = isAnyStatus(creditAccountStatus, [
    CREDIT_ACCOUNT_STATUS.ACTIVE,
    CREDIT_ACCOUNT_STATUS.ON_HOLD,
    CREDIT_ACCOUNT_STATUS.PENDING,
  ]);

  return {
    isCreditAccountInfoModalVisible,
    paymentMethods: {
      isPageBlocked: isPaymentMethodsPageBlocked,
      isCreditAccountBannerVisible,
    },
    creditAccount: {
      isNewBadgeDisplayed,
      isPageBlocked: isCreditAccountPageBlocked,
      isPendingBannerVisible: isCreditAccountPendingBannerVisible,
      isRejectedBannerVisible: isCreditAccountRejectedBannerVisible,
    },
    invoices: {
      isPageBlocked: isInvoicesPageBlocked,
    },
    billing: {
      isReadonly: isBillingPageReadonly,
    },
  };
};

export default creditAccountMigrationConfigManager;
