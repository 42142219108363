import { ROUTES } from 'constants.js';
import Sessions from 'pages/Sessions';
import Drivers from 'pages/Drivers';
import Vehicles from 'pages/Vehicles';
import CreditAccount from 'pages/CreditAccount';
import VehiclesUpload from 'pages/Vehicles/VehiclesUpload';
import Reports from 'pages/Reports';
import Corporations from 'pages/Corporations';
import CorporationsAdmin from 'pages/PbpStaff/CorporationsAdmin';
import StaffManagement from 'pages/PbpStaff/StaffManagement';
import CorporationsEdit from 'pages/PbpStaff/EditCorporation';
import CorporationsProfile from 'pages/PbpStaff/CreateCorporation';
import LoginRedirect from 'pages/Auth/LoginRedirect';
import LogoutRedirect from 'pages/Auth/LogoutRedirect';
import SilentRenew from 'pages/Auth/SilentRenew';
import Activate from 'pages/Auth/Activate';
import ActivationRedirect from 'pages/Auth/ActivationRedirect';
import Admins from 'pages/Admins';
import PaymentsSpa from 'pages/PaymentsSpa';
import PASFormView from 'pages/PaymentsSpa/PASFormView';
import MonthlyStatements from 'pages/MonthlyStatements';
import Onboarding from 'pages/Onboarding';
import SelfRegistration from 'pages/SelfRegistration';
import Billing from 'pages/Billing';
import VerificationPage from 'pages/VerificationPage';
import AuditLogs from 'pages/AuditLogs';
import InvoicesAndStatements from 'pages/InvoicesAndStatements';

export const baseRoutes = [
  {
    path: ROUTES.AUTH_REDIRECT,
    component: LoginRedirect,
    exact: true,
  },
  {
    path: ROUTES.AUTH_LOGOUT_REDIRECT,
    component: LogoutRedirect,
    exact: true,
  },
  {
    path: ROUTES.AUTH_SILENT_RENEW,
    component: SilentRenew,
    exact: true,
  },
  {
    path: ROUTES.ACTIVATE,
    component: Activate,
    exact: true,
  },
  {
    path: ROUTES.ACTIVATE_REDIRECT,
    component: ActivationRedirect,
    exact: true,
  },
];

export const adminRoutes = [
  { path: ROUTES.STAFF_USERS, component: StaffManagement, exact: true },
  {
    path: ROUTES.CORPORATIONS_EDIT,
    component: CorporationsEdit,
    exact: true,
  },
  {
    path: ROUTES.CORPORATIONS_CREATE,
    component: CorporationsProfile,
    exact: true,
  },
  {
    path: ROUTES.CORPORATIONS_ADMIN,
    component: CorporationsAdmin,
    exact: true,
  },
  {
    path: ROUTES.AUDIT_LOGS,
    component: AuditLogs,
    exact: true,
  },
];

export const businessUserRoutes = [
  {
    path: ROUTES.CORPORATIONS,
    component: Corporations,
    exact: true,
  },
];

export const corporationRoutes = [
  {
    path: ROUTES.SESSIONS,
    component: Sessions,
    exact: true,
  },
  {
    path: ROUTES.DRIVERS,
    component: Drivers,
    exact: true,
  },
  {
    path: ROUTES.VEHICLES,
    component: Vehicles,
    exact: true,
  },
  {
    path: ROUTES.VEHICLES_UPLOAD,
    component: VehiclesUpload,
    exact: true,
  },
  {
    path: ROUTES.REPORTS,
    component: Reports,
    exact: true,
  },
  {
    path: ROUTES.PAYMENTS,
    component: PaymentsSpa,
    exact: true,
  },
  {
    path: ROUTES.PAYMENTS_CREATE,
    component: PASFormView,
    exact: true,
  },
  {
    path: ROUTES.PAYMENTS_EDIT,
    component: PASFormView,
    exact: true,
  },
  {
    path: ROUTES.USERS,
    component: Admins,
    exact: true,
  },
  {
    path: ROUTES.MONTHLY_STATEMENTS,
    component: MonthlyStatements,
    exact: true,
  },
  {
    path: ROUTES.BILLING,
    component: Billing,
    exact: true,
  },
  {
    path: ROUTES.CREDIT_ACCOUNT,
    featureComponent: CreditAccount,
    exact: true,
    feature: 'creditAccount',
  },
  {
    path: ROUTES.INVOICES,
    featureComponent: InvoicesAndStatements,
    exact: true,
    feature: 'creditAccount',
  },
];

export const publicRoutes = [
  {
    path: ROUTES.ONBOARDING,
    component: Onboarding,
    exact: true,
  },
  {
    path: ROUTES.REGISTRATION,
    component: SelfRegistration,
    exact: true,
  },
  {
    path: ROUTES.VERIFY_EMAIL,
    component: VerificationPage,
    exact: true,
  },
];

export const restrictedCorporateClientProspectRoutes = [
  {
    path: ROUTES.DRIVERS,
    component: Drivers,
    exact: true,
  },
];

export default {
  adminRoutes,
  businessUserRoutes,
  corporationRoutes,
  baseRoutes,
};
