export const INVOICE_STATUS = {
  PAID: 'paid',
  UNPAID: 'unpaid',
  OVERDUE: 'overdue',
};

export const INVOICE_STATUS_MAPPER = {
  PAID: INVOICE_STATUS.PAID,
  NOTPAID: INVOICE_STATUS.UNPAID, // backend calls this notPaid
  OVERDUE: INVOICE_STATUS.OVERDUE,
};

export const INVOICE_STATUS_TAGS = {
  [INVOICE_STATUS.PAID]: {
    localizeKey: 'corporateAccounts.invoicesAndStatements.invoiceStatus.paid',
    utilTagType: 'completed',
  },
  [INVOICE_STATUS.UNPAID]: {
    localizeKey: 'corporateAccounts.invoicesAndStatements.invoiceStatus.unpaid',
    utilTagType: 'pending',
  },
  [INVOICE_STATUS.OVERDUE]: {
    localizeKey: 'corporateAccounts.invoicesAndStatements.invoiceStatus.overdue',
    utilTagType: 'failed',
  },
};

export const ALLSTAR_DOCUMENT_TYPES = {
  SUMMARY: 'Agency Invoice', // maps to Summary (pdf)
  STATEMENT: 'Parking Statement', // maps to invoice (pdf)
};

export const UNFORMATTED_MOCK_DATA = [
  {
    billingDate: '2024-10-24',
    totalGross: '1400.23',
    paymentDueDate: '2024-10-31',
    currency: 'GBP',
    openingAmount: '0',
    status: 'Paid',
  },
  {
    billingDate: '2024-10-25',
    totalGross: '1500.23',
    paymentDueDate: '2024-10-01',
    currency: 'GBP',
    openingAmount: '0',
    status: 'NotPaid',
  },
  {
    billingDate: '2024-10-26',
    totalGross: '1600.23',
    paymentDueDate: '2024-11-02',
    currency: 'GBP',
    openingAmount: '0',
    status: 'Overdue',
  },
];

export const FORMATTED_MOCK_DATA = [
  {
    invoiceId: 0,
    billingDate: '24/10/2024',
    totalGross: '£1400.23',
    paymentDueDate: '31/10/2024',
    currency: 'GBP',
    openingAmount: '£0.00',
    status: 'Paid',
  },
  {
    invoiceId: 1,
    billingDate: '25/10/2024',
    totalGross: '£1500.23',
    paymentDueDate: '01/10/2024',
    currency: 'GBP',
    openingAmount: '£0.00',
    status: 'NotPaid',
  },
  {
    invoiceId: 2,
    billingDate: '26/10/2024',
    totalGross: '£1600.23',
    paymentDueDate: '02/11/2024',
    currency: 'GBP',
    openingAmount: '£0.00',
    status: 'Overdue',
  },
];
