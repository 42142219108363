import moment from 'moment';
import { CURRENCY } from 'constants.js';

export const formatCurrency = (currencyCode, value) => {
  if (!value) return;
  const currencyValue = Number(value).toFixed(2);
  if (currencyCode === 'GBP') return `${CURRENCY.GB}${currencyValue}`;
  if (currencyCode === 'EUR') return `${CURRENCY.FR}${currencyValue}`;
  return `${CURRENCY.CA}${currencyValue}`;
};

export const formatInvoiceSummaryList = invoiceSummaryList => {
  if (!invoiceSummaryList) return [];
  return invoiceSummaryList.map((invoice, idx) => {
    return {
      ...invoice,
      billingDate: moment(invoice.billingDate).format('DD/MM/YYYY'),
      paymentDueDate: moment(invoice.paymentDueDate).format('DD/MM/YYYY'),
      totalGross: formatCurrency(invoice.currency, invoice.totalGross),
      openingAmount: formatCurrency(invoice.currency, invoice.openingAmount),
      invoiceId: idx,
    };
  });
};

export const convertDataToPDF = (data, fileName) => {
  if (data && data.Data && data.Data.PdfData) {
    const pdfData = data.Data.PdfData;
    const byteCharacters = atob(pdfData);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  }
};

export default { formatInvoiceSummaryList, convertDataToPDF };
