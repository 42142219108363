import React, { useState, useEffect } from 'react';
import { Typography, Checkbox } from 'antd';
import ExtendedModal from 'components/Shared/Modal';
import useTranslation from 'utils/hooks/useTranslation';
import useAmplitude from 'utils/hooks/useAmplitude';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import { ALLSTAR_DOCUMENT_TYPES } from 'constants/invoicesAndStatements';

import styles from './style.module.scss';

const InvoiceModal = ({
  visible,
  onClose,
  loading,
  handleInvoiceModalVisibility,
  checkboxOptions,
  documentLinks,
  setSummaryUrl,
  setInvoiceUrl,
}) => {
  const { translateText } = useTranslation();
  const { sendAmplitudeEvent } = useAmplitude();
  const [checkedList, setCheckedList] = useState([]);
  const [availableDocuments, setAvailableDocuments] = useState([]);
  const [availableCheckboxOptions, setAvailableCheckboxOptions] = useState([]);

  const isDocumentLinkAvailable = documentLinks && documentLinks.length > 0;

  useEffect(() => {
    const mapDocuments = () => {
      const documents = documentLinks.map(link => ({
        documentType: link.documentType,
        url: link.pdf.url,
      }));
      setAvailableDocuments(documents);
    };

    if (documentLinks && documentLinks.length > 0) {
      mapDocuments();
    }
  }, [documentLinks]);

  useEffect(() => {
    const options = [];

    if (availableDocuments.some(doc => doc.documentType === ALLSTAR_DOCUMENT_TYPES.SUMMARY)) {
      options.push(checkboxOptions[0]);
    }
    if (availableDocuments.some(doc => doc.documentType === ALLSTAR_DOCUMENT_TYPES.STATEMENT)) {
      options.push(checkboxOptions[1]);
    }

    setAvailableCheckboxOptions(options);
  }, [availableDocuments, checkboxOptions]);

  const onChange = checkedValues => {
    setCheckedList(checkedValues);
  };

  const handleOk = () => {
    if (checkedList.includes(checkboxOptions[0])) {
      const summaryDoc = availableDocuments.find(
        doc => doc.documentType === ALLSTAR_DOCUMENT_TYPES.SUMMARY,
      );
      if (summaryDoc) {
        setSummaryUrl(summaryDoc.url);
      }
    }
    if (checkedList.includes(checkboxOptions[1])) {
      const statementDoc = availableDocuments.find(
        doc => doc.documentType === ALLSTAR_DOCUMENT_TYPES.STATEMENT,
      );
      if (statementDoc) {
        setInvoiceUrl(statementDoc.url);
      }
    }
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.invoiceTableDownloadInvoice);
    setCheckedList([]);
    handleInvoiceModalVisibility({ invoiceId: null });
  };

  const handleCancel = () => {
    setCheckedList([]);
    onClose();
  };

  return (
    <ExtendedModal
      title={translateText('corporateAccounts.invoicesAndStatements.downloadInvoice')}
      modalTitleStyle="centerAligned"
      titleLevel={4}
      width={400}
      visible={visible}
      centered
      closable={false}
      onCancel={handleCancel}
      onOk={handleOk}
      okText={translateText('corporateAccounts.actions.download')}
      okButtonProps={{
        'data-testid': 'invoiceModalDownloadButton',
        disabled: checkedList.length === 0,
      }}
      confirmLoading={loading}
      className={styles.invoiceModal}
    >
      {isDocumentLinkAvailable ? (
        <div className={styles.invoiceModalContent}>
          <Typography>
            {translateText('corporateAccounts.invoicesAndStatements.modal.description')}
          </Typography>
          <Checkbox.Group
            className={styles.invoiceModalCheckbox}
            options={availableCheckboxOptions}
            value={checkedList}
            onChange={onChange}
          />
        </div>
      ) : (
        <div className={styles.invoiceModalNoDownload}>
          <Typography>
            {translateText('corporateAccounts.invoicesAndStatements.modal.noDownload')}
          </Typography>
        </div>
      )}
    </ExtendedModal>
  );
};

export default InvoiceModal;
