import React from 'react';
import { Button, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import useTranslation from 'utils/hooks/useTranslation';
import {
  MenuLeftDriversIcon,
  MenuLeftVehiclesIcon,
  MenuLeftCreditAccountIcon,
  MenuLeftMonthlyStatementsIcon,
} from 'components/Shared/Icons';

import styles from './style.module.scss';

const CreditAccountCorporationRegistration = ({ onSubmit, isLoading }) => {
  const { translateText } = useTranslation();

  const { Title, Text } = Typography;

  const BuildIcon = ({ Icon, title }) => {
    return (
      <div className={styles.iconWrapper}>
        <div className={styles.iconCircle}>
          <Icon color="#FFFFFF" />
        </div>
        <Title level={5} className={styles.iconTitle}>
          {title}
        </Title>
      </div>
    );
  };

  const infoGraph = (
    <div className={styles.infoGraphContainer}>
      <div className={styles.driversAndVehicles}>
        <BuildIcon
          Icon={MenuLeftDriversIcon}
          title={translateText('corporateAccounts.breadcrumbs.drivers')}
        />
        <Text>+</Text>
        <BuildIcon
          Icon={MenuLeftVehiclesIcon}
          title={translateText('corporateAccounts.breadcrumbs.vehicles')}
        />
      </div>
      <ArrowRightOutlined style={{ fontSize: '20px' }} />
      <BuildIcon
        Icon={MenuLeftCreditAccountIcon}
        title={translateText('corporateAccounts.corporations.register.paymentMethod')}
      />
      <ArrowRightOutlined style={{ fontSize: '20px' }} />
      <BuildIcon
        Icon={MenuLeftMonthlyStatementsIcon}
        title={translateText('corporateAccounts.common.statements')}
      />
    </div>
  );

  return (
    <div className={styles.RegistrationInfoContainer}>
      <Title level={3}>{translateText('corporateAccounts.corporations.register.title')}</Title>
      {infoGraph}
      <Text>{translateText('corporateAccounts.corporations.register.intro.message1')}</Text>
      <Text>{translateText('corporateAccounts.corporations.register.intro.message2')}</Text>
      <Button
        type="primary"
        size="large"
        data-testid="saveButton"
        onClick={onSubmit}
        loading={isLoading}
      >
        {translateText('corporateAccounts.onboarding.welcomeModal.actions.getStarted')}
      </Button>
    </div>
  );
};

export default CreditAccountCorporationRegistration;
