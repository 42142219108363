import { httpWrapper } from 'services/http';
import userService from 'services/user';
import { API_URL_REPORTS_V1 } from 'constants.js';
import { endpoint } from '.';

const ROOT_ENDPOINT = `${API_URL_REPORTS_V1}/corporateClients`;
const BASE_ENDPOINT = '/parkingStatements';

const download = async id => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'GET',
    url: `${endpoint(BASE_ENDPOINT, true, ROOT_ENDPOINT)}/${id}/downloadUrl`,
    headers: {
      ...authHeaders,
    },
  };

  return httpWrapper(requestOptions);
};

const readAll = async () => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'GET',
    url: endpoint(BASE_ENDPOINT, true, ROOT_ENDPOINT),
    headers: {
      ...authHeaders,
    },
  };

  return httpWrapper(requestOptions);
};

export default {
  download,
  readAll,
};
