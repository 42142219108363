import React from 'react';
import { Form, Input, Row, Typography } from 'antd';
import { FORM_FIELD_NAMES } from 'constants/selfRegistration';
import { checkCorporateAccountsPasswordPolicies } from 'utils/passwordUtils';
import CheckCircleGreenIcon from 'components/Shared/Icons/CheckCircleGreenIcon';
import CheckCircleGreyIcon from 'components/Shared/Icons/CheckCircleGreyIcon';

import styles from './style.module.scss';

const { Item } = Form;

const PasswordInputWithCheckmarkValidation = ({ translateText, appearance = null }) => {
  return (
    <Item
      name={FORM_FIELD_NAMES.password}
      label={translateText('corporateAccounts.common.password')}
      className={appearance && styles[appearance]}
      data-testid={FORM_FIELD_NAMES.password}
      rules={[
        {
          required: true,
          type: 'password',
          validator: async (_, value) => {
            const validationResults = checkCorporateAccountsPasswordPolicies(value, translateText);
            const isValid = Object.values(validationResults).every(({ isValid }) => isValid);
            if (isValid) return Promise.resolve();

            const validationMessages = Object.values(validationResults).map(
              ({ isValid, message }) => {
                return (
                  <span key={message} className={styles.passwordValidationCol}>
                    {isValid ? <CheckCircleGreenIcon /> : <CheckCircleGreyIcon />}
                    <div className={styles.passwordValidation}>
                      <Typography.Text>{message}</Typography.Text>
                    </div>
                  </span>
                );
              },
            );

            const validationMessageCols = (
              <div className={styles.validationMessageWrapper}>
                {validationMessages.map((message, index) => (
                  <div key={index} className={styles.columnStyle}>
                    {message}
                  </div>
                ))}
              </div>
            );

            return Promise.reject(<Row gutter={16}>{validationMessageCols}</Row>);
          },
        },
      ]}
    >
      <Input.Password />
    </Item>
  );
};

export default PasswordInputWithCheckmarkValidation;
