import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  useRegisterCorporation,
  useRegisterCreditAccountCorporation,
} from 'services/reactQueryHooks/corporations';
import useAmplitude from 'utils/hooks/useAmplitude';
import { useFeatureFlag } from 'components/Providers/FeatureFlag';
import { formatCorprateClientData } from './helper';

const useRegisterCorporationModal = () => {
  const { sendAmplitudeEvent } = useAmplitude();
  const { showFeature } = useFeatureFlag();

  const { isMobileView: isMobile, locale: preferredLanguage } = useSelector(
    state => state?.settings || {},
  );
  const { restrictedCorporateClientProspect, id: userId } = useSelector(state => state?.user || {});

  const {
    mutate: registerCorporation,
    isLoading: isRegisterCorporationLoading,
    isSuccess: isRegisterCorporationSuccess,
  } = useRegisterCorporation();

  const {
    mutate: registerCreditAccountCorporation,
    isLoading: isRegisterCreditAccountCorporationLoading,
    isSuccess: isRegisterCreditAccountCorporationSuccess,
  } = useRegisterCreditAccountCorporation();

  const handleRegistration = useCallback(
    ({ formData }) => {
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationCorporateClientRegistrationSave);
      formData = showFeature('selfRegistrationV2')
        ? formatCorprateClientData({
            ...formData,
            userAccountId: userId,
            preferredLanguage: preferredLanguage,
            country: 'CA',
          })
        : { ...formData, userAccountId: userId, preferredLanguage: preferredLanguage };

      registerCorporation(formData);
    },
    [preferredLanguage, registerCorporation, sendAmplitudeEvent, showFeature, userId],
  );

  const handleCreditAccountRegistration = useCallback(() => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationCreditAccountClientRegistrationSave);

    registerCreditAccountCorporation({ userAccountId: userId, preferredLanguage: 'en-GB' });
  }, [registerCreditAccountCorporation, sendAmplitudeEvent, userId]);

  return {
    isMobile,
    isRegisterCorporationLoading,
    isRegisterCorporationSuccess,
    isRegisterCreditAccountCorporationLoading,
    isRegisterCreditAccountCorporationSuccess,
    prospectUser: restrictedCorporateClientProspect,
    onRegister: handleRegistration,
    onCreditAccountCorporationRegister: handleCreditAccountRegistration,
  };
};

export default useRegisterCorporationModal;
