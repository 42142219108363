import React, { useState } from 'react';
import { Col, Row, Select, DatePicker } from 'antd';
import useTranslation from 'utils/hooks/useTranslation';
import moment from 'moment';
import { injectIntl } from 'react-intl';

import styles from './style.module.scss';

const { Option } = Select;

const TableControl = ({ setSelectedDateRange }) => {
  const { translateText } = useTranslation();
  const [selectedRange, setSelectedRange] = useState(null);
  const [datePickerValue, setDatePickerValue] = useState(null);

  const availableDateRanges = {
    week: {
      label: translateText('corporateAccounts.common.week'),
      value: 'week',
    },
    month: {
      label: translateText('corporateAccounts.common.month'),
      value: 'month',
    },
    quarter: {
      label: translateText('corporateAccounts.common.quarter'),
      value: 'quarter',
    },
    year: {
      label: translateText('corporateAccounts.common.year'),
      value: 'year',
    },
  };

  const onChange = date => {
    if (date) {
      setDatePickerValue(date);
      setSelectedDateRange({
        startDate: moment(date)
          .startOf(selectedRange)
          .format('YYYY-MM-DD'),
        endDate: moment(date)
          .endOf(selectedRange)
          .format('YYYY-MM-DD'),
      });
    }
  };

  const handleRangeChange = value => {
    setSelectedRange(value);
    setDatePickerValue(null);
  };

  return (
    <Row justify="start" className={styles.dateRangeContainer}>
      <Col>
        <Select
          className={styles.dateRangeSelector}
          placeholder={translateText('corporateAccounts.reports.transactions.chooseDateRange')}
          onChange={handleRangeChange}
          value={selectedRange}
        >
          {Object.keys(availableDateRanges).map(key => (
            <Option key={availableDateRanges[key].value} value={availableDateRanges[key].value}>
              {availableDateRanges[key].label}
            </Option>
          ))}
        </Select>
      </Col>
      <Col>
        {selectedRange && (
          <DatePicker
            className={styles.datePicker}
            onChange={onChange}
            picker={typeof selectedRange === 'string' && selectedRange.toLowerCase()}
            value={datePickerValue}
            allowClear={false}
          />
        )}
      </Col>
    </Row>
  );
};

export default injectIntl(TableControl);
