import { httpWrapper } from 'services/http';
import userService from 'services/user';
import { endpointCorporateClient } from '.';
import { API_URL_REPORTS, API_URL_REPORTS_V1 } from 'constants.js';

const getInvoiceSummaryList = async payload => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'GET',
    url: `${endpointCorporateClient('/invoices', true, API_URL_REPORTS_V1)}?startDate=${
      payload?.startDate
    }&endDate=${payload?.endDate}`,
    headers: {
      ...authHeaders,
    },
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const getInvoiceDocument = async url => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'GET',
    url: `${API_URL_REPORTS}` + url,
    headers: {
      ...authHeaders,
    },
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const creditAccounts = {
  getInvoiceSummaryList,
  getInvoiceDocument,
};

export default creditAccounts;
