import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Tabs } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import { injectIntl } from 'react-intl';
import Authorize from 'components/Providers/Authorize';
import PageWrapper from 'components/Shared/Page/Wrapper';
import PageTitle from 'components/Shared/Page/Title';
import withPusher from 'components/Providers/Pusher/withPusher';
import PageBanners from 'components/Layout/PageBanners';
import useTranslation from 'utils/hooks/useTranslation';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import Transactions from './Transactions';
import MonthlyReport from './MonthlyReports';
import useAmplitude from '../../utils/hooks/useAmplitude';
import TransactionReportIcon from 'components/Shared/Icons/TransactionReportIcon';

import styles from './style.module.scss';

const { TabPane } = Tabs;

const Reports = ({ history }) => {
  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();
  const [activeTransactionsTab, setActiveTransactionsTab] = useState(true);

  const handleMonthlyClick = key => {
    if (key === '2') {
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.monthlyTab);
      setActiveTransactionsTab(false);
    } else {
      setActiveTransactionsTab(true);
    }
  };

  useEffect(() => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.reportPage);
  }, [sendAmplitudeEvent]);

  return (
    <Authorize>
      <Helmet title="Reports" />
      <PageBanners />
      <PageWrapper history={history}>
        <PageTitle title={translateText('corporateAccounts.reports.main.title')} />
        <div className="card-body">
          <Tabs defaultActiveKey="1" onChange={handleMonthlyClick}>
            <TabPane
              tab={
                <span className={styles.transactionReportTab}>
                  <TransactionReportIcon active={activeTransactionsTab} />
                  {translateText('corporateAccounts.reports.transactions')}
                </span>
              }
              key="1"
            >
              <Transactions />
            </TabPane>
            <TabPane
              data-testid={'monthlyReportsBtn'}
              tab={
                <span>
                  <CalendarOutlined />
                  {translateText('corporateAccounts.reports.monthlyReports')}
                </span>
              }
              key="2"
            >
              <MonthlyReport />
            </TabPane>
          </Tabs>
        </div>
      </PageWrapper>
    </Authorize>
  );
};

export default withPusher(injectIntl(Reports));
