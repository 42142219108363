import React from 'react';

const MenuLeftInvoicesAndStatementsIcon = () => (
  <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon /FileDone">
      <path
        id="Vector"
        d="M11.1423 4.42532V3.56817C11.1423 3.4896 11.078 3.42532 10.9994 3.42532H4.1423C4.06373 3.42532 3.99944 3.4896 3.99944 3.56817V4.42532C3.99944 4.50389 4.06373 4.56817 4.1423 4.56817H10.9994C11.078 4.56817 11.1423 4.50389 11.1423 4.42532ZM4.1423 5.99674C4.06373 5.99674 3.99944 6.06103 3.99944 6.1396V6.99674C3.99944 7.07532 4.06373 7.1396 4.1423 7.1396H7.42801C7.50659 7.1396 7.57087 7.07532 7.57087 6.99674V6.1396C7.57087 6.06103 7.50659 5.99674 7.42801 5.99674H4.1423ZM10.8566 8.06817C8.72623 8.06817 6.99944 9.79496 6.99944 11.9253C6.99944 14.0557 8.72623 15.7825 10.8566 15.7825C12.9869 15.7825 14.7137 14.0557 14.7137 11.9253C14.7137 9.79496 12.9869 8.06817 10.8566 8.06817ZM12.7762 13.845C12.2637 14.3575 11.5816 14.6396 10.8566 14.6396C10.1316 14.6396 9.44944 14.3575 8.93694 13.845C8.42444 13.3325 8.1423 12.6503 8.1423 11.9253C8.1423 11.2003 8.42444 10.5182 8.93694 10.0057C9.44944 9.49317 10.1316 9.21103 10.8566 9.21103C11.5816 9.21103 12.2637 9.49317 12.7762 10.0057C13.2887 10.5182 13.5709 11.2003 13.5709 11.9253C13.5709 12.6503 13.2887 13.3325 12.7762 13.845ZM12.4459 10.5682H11.6548C11.6084 10.5682 11.5655 10.5896 11.5387 10.6271L10.4048 12.195L9.9923 11.6253C9.97909 11.6069 9.96165 11.592 9.94145 11.5817C9.92125 11.5714 9.89888 11.5662 9.87623 11.5664H9.08873C8.97266 11.5664 8.9048 11.6985 8.97266 11.7932L10.2905 13.6164C10.3477 13.695 10.4637 13.695 10.5209 13.6164L12.5602 10.795C12.6298 10.7003 12.5619 10.5682 12.4459 10.5682ZM6.71373 14.0682H2.57087V1.49674H12.5709V7.6396C12.5709 7.71817 12.6352 7.78246 12.7137 7.78246H13.7137C13.7923 7.78246 13.8566 7.71817 13.8566 7.6396V0.782458C13.8566 0.466386 13.6012 0.211029 13.2852 0.211029H1.85658C1.54051 0.211029 1.28516 0.466386 1.28516 0.782458V14.7825C1.28516 15.0985 1.54051 15.3539 1.85658 15.3539H6.71373C6.7923 15.3539 6.85659 15.2896 6.85659 15.211V14.211C6.85659 14.1325 6.7923 14.0682 6.71373 14.0682Z"
        fill="#318145"
      />
    </g>
  </svg>
);

export default MenuLeftInvoicesAndStatementsIcon;
