import { ROUTES } from 'constants.js';
import {
  MenuLeftDriversIcon,
  MenuLeftVehiclesIcon,
  MenuLeftSessionsIcon,
  MenuLeftReportsIcon,
  MenuLeftMonthlyStatementsIcon,
  MenuLeftPaymentMethodsIcon,
  MenuLeftUsersIcon,
  MenuLeftAuditLogsIcon,
  MenuLeftBillingIcon,
  MenuLeftSuperAdminHomeIcon,
  MenuLeftStaffAccountsIcon,
  MenuLeftStaffUsersIcon,
  MenuLeftCreditAccountIcon,
  MenuLeftInvoicesAndStatementsIcon,
} from 'components/Shared/Icons';

// Attach `feature` key value pair if menu item needs to be feature toggled
export const corporateClientMenuItems = [
  {
    title: 'Drivers',
    key: 'drivers',
    url: ROUTES.DRIVERS,
    component: MenuLeftDriversIcon,
  },
  {
    title: 'Vehicles',
    key: 'vehicles',
    url: ROUTES.VEHICLES,
    subPaths: [ROUTES.VEHICLES_UPLOAD],
    component: MenuLeftVehiclesIcon,
  },
  {
    title: 'Credit account',
    key: 'creditAccount',
    url: ROUTES.CREDIT_ACCOUNT,
    component: MenuLeftCreditAccountIcon,
    feature: 'creditAccount',
    newFeature: true,
  },
  {
    title: 'Sessions',
    key: 'sessions',
    url: ROUTES.SESSIONS,
    component: MenuLeftSessionsIcon,
  },
  {
    title: 'Reports',
    key: 'reports',
    url: ROUTES.REPORTS,
    component: MenuLeftReportsIcon,
  },
  {
    title: 'Monthly Statements',
    key: 'monthlyStatements',
    url: ROUTES.MONTHLY_STATEMENTS,
    feature: 'monthlyStatements',
    component: MenuLeftMonthlyStatementsIcon,
  },
  {
    title: 'Payment Info',
    key: 'payments',
    url: ROUTES.PAYMENTS,
    subPaths: [ROUTES.PAYMENTS_CREATE, ROUTES.PAYMENTS_EDIT],
    component: MenuLeftPaymentMethodsIcon,
  },
  {
    title: 'Billing and Subscription',
    key: 'billing',
    url: ROUTES.BILLING,
    component: MenuLeftBillingIcon,
  },
  {
    title: 'Invoices and Statements',
    key: 'invoicesAndStatements',
    url: ROUTES.INVOICES,
    component: MenuLeftInvoicesAndStatementsIcon,
    feature: 'creditAccount',
  },
  {
    title: 'Users',
    key: 'admins',
    url: ROUTES.USERS,
    component: MenuLeftUsersIcon,
  },
];

export const staffOnlyMenuItems = [
  {
    title: 'Audit Logs',
    key: 'auditLogs',
    url: ROUTES.AUDIT_LOGS,
    component: MenuLeftAuditLogsIcon,
    staffOnly: true,
  },
  {
    title: 'Superadmin home',
    key: 'superAdminHome',
    url: ROUTES.ADMIN_DEFAULT,
    component: MenuLeftSuperAdminHomeIcon,
    staffOnly: true,
  },
];

export const staffUserMenuItems = [
  {
    title: 'Accounts',
    key: 'accounts',
    url: ROUTES.CORPORATIONS_ADMIN,
    component: MenuLeftStaffAccountsIcon,
    superAdminViewOnly: false,
  },
  {
    title: 'PBP Staff Users',
    key: 'staffUsers',
    url: ROUTES.STAFF_USERS,
    component: MenuLeftStaffUsersIcon,
    superAdminViewOnly: true,
  },
];

const getCorporateClientMenuItem = location => {
  const allMenuItems = [...corporateClientMenuItems, ...staffOnlyMenuItems];
  return allMenuItems.find(
    x => x.url === location.pathname || x.subPaths?.includes(location.pathname),
  );
};

const getStaffUserMenuItem = location => {
  return staffUserMenuItems.find(x => x.url === location.pathname);
};

export const getMenuItemByLocation = (adminRole, location) => {
  if (!location || !location.pathname) {
    return undefined;
  }

  return location.pathname.includes('/admin/') && adminRole
    ? getStaffUserMenuItem(location)
    : getCorporateClientMenuItem(location, adminRole);
};

export const isMenuItemRestricted = (menuItem, creditAccountMigrationConfig, isAdmin) => {
  if (
    menuItem.key === 'payments' &&
    creditAccountMigrationConfig?.paymentMethods?.isPageBlocked &&
    !isAdmin
  ) {
    return true;
  }

  if (
    menuItem.key === 'creditAccount' &&
    creditAccountMigrationConfig?.creditAccount?.isPageBlocked
  ) {
    return true;
  }

  if (
    menuItem.key === 'invoicesAndStatements' &&
    creditAccountMigrationConfig?.invoices?.isPageBlocked
  ) {
    return true;
  }

  return false;
};
