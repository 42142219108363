import { useMemo, useEffect } from 'react';
import {
  useGetInvoiceSummaryList,
  useGetInvoiceDocument,
} from 'services/reactQueryHooks/invoicesAndStatements';
import { formatInvoiceSummaryList, convertDataToPDF } from './helper';

const useInvoiceAndStatementsPage = (
  timeFrame,
  summaryUrl,
  setSummaryUrl,
  invoiceUrl,
  setInvoiceUrl,
) => {
  const {
    data: invoiceSummaryList,
    isLoading: isInvoiceSummaryListLoading,
    isSuccess: isInvoiceSummaryListLoadingSuccessful,
  } = useGetInvoiceSummaryList(timeFrame);

  const {
    data: summaryDocument,
    isLoading: isSummaryDocumentLoading,
    isSuccess: isSummaryDocumentLoadingSuccessful,
  } = useGetInvoiceDocument(summaryUrl);

  const {
    data: invoiceDocument,
    isLoading: isInvoiceDocumentLoading,
    isSuccess: isInvoiceDocumentLoadingSuccessful,
  } = useGetInvoiceDocument(invoiceUrl);

  useEffect(() => {
    if (summaryDocument) {
      convertDataToPDF(summaryDocument, 'summary.pdf');
      setSummaryUrl(null);
    }
  }, [summaryDocument, setSummaryUrl]);

  useEffect(() => {
    if (invoiceDocument) {
      convertDataToPDF(invoiceDocument, 'invoice.pdf');
      setInvoiceUrl(null);
    }
  }, [invoiceDocument, setInvoiceUrl]);

  const formattedInvoiceSummaryList = useMemo(() => {
    return formatInvoiceSummaryList(invoiceSummaryList);
  }, [invoiceSummaryList]);

  return {
    invoiceSummaryList: formattedInvoiceSummaryList,
    isInvoiceSummaryListLoadingSuccessful,
    isInvoiceSummaryListLoading,
    invoiceDocument,
    isInvoiceDocumentLoading,
    isInvoiceDocumentLoadingSuccessful,
    summaryDocument,
    isSummaryDocumentLoading,
    isSummaryDocumentLoadingSuccessful,
  };
};

export default useInvoiceAndStatementsPage;
