import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Button } from 'antd';
import useTranslation from 'utils/hooks/useTranslation';
import { ExitIcon } from 'components/Shared/Icons';
import ExtendedModal from 'components/Shared/Modal';
import { ALLSTAR_CREDIT_ACCOUNT_URL } from 'constants.js';

import styles from './style.module.scss';

const CreditAccountRedirectModal = ({ isModalVisible, setIsModalVisible }) => {
  const { translateText } = useTranslation();
  const corporateClientId = useSelector(state => state.user.corporateClientId);
  const allStarLink = `${ALLSTAR_CREDIT_ACCOUNT_URL}/ete/pbp?pbpClientId=${corporateClientId}`;

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleContinue = () => {
    window.open(allStarLink);
    setIsModalVisible(false);
  };

  return (
    <ExtendedModal
      title={''}
      modalTitleStyle="centerAligned"
      titleLevel={4}
      width={565}
      visible={isModalVisible}
      centered
      closable={false}
      onCancel={handleClose}
      okButtonProps={{ 'data-testid': 'redirectModalButton' }}
      confirmLoading={false}
      footer={[
        <div key="footer" className={styles.footerButtons}>
          <Button
            key="cancel"
            onClick={handleClose}
            className={`${styles.sharedButton} ${styles.cancelButton}`}
          >
            {translateText('corporateAccounts.actions.cancel')}
          </Button>
          <Button key="ok" onClick={handleContinue} type="primary" className={styles.sharedButton}>
            {translateText('corporateAccounts.actions.continue')}
          </Button>
        </div>,
      ]}
      className={styles.redirectModal}
    >
      <>
        <ExitIcon />
        <Typography.Title level={4}>
          {translateText('corporateAccounts.creditAccount.redirectModal.title')}
        </Typography.Title>
        <Typography className={styles.redirectModalContent}>
          {translateText('corporateAccounts.creditAccount.redirectModal.description')}
        </Typography>
      </>
    </ExtendedModal>
  );
};

export default CreditAccountRedirectModal;
