import { useQuery } from 'react-query';
import invoiceAndSummary from 'services/api/invoiceAndSummary';
import notify from 'utils/notificationUtils';
import useTranslation from 'utils/hooks/useTranslation';

export const useGetInvoiceSummaryList = payload => {
  const { translateText } = useTranslation();
  const getInvoiceSummarylist = async () => {
    const result = await invoiceAndSummary.getInvoiceSummaryList(payload);
    return result?.data;
  };

  return useQuery({
    queryKey: [{ payload }],
    queryFn: getInvoiceSummarylist,
    enabled: !!payload,
    retry: false,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    onError: () => {
      notify({
        type: 'error',
        title: translateText('corporateAccounts.invoicesAndStatements.error.title'),
        message: translateText('corporateAccounts.invoicesAndStatements.error'),
      });
    },
  });
};

export const useGetInvoiceDocument = url => {
  const { translateText } = useTranslation();

  const getInvoiceDocument = async () => {
    const result = await invoiceAndSummary.getInvoiceDocument(url);
    return result?.data;
  };

  return useQuery({
    queryKey: [url],
    queryFn: getInvoiceDocument,
    enabled: !!url,
    retry: false,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      notify({
        type: 'success',
        message: translateText('corporateAccounts.invoicesAndStatements.modal.success'),
      });
    },
    onError: () => {
      notify({
        type: 'error',
        message: translateText('corporateAccounts.invoicesAndStatements.modal.error'),
      });
    },
  });
};
