import {
  createFeature,
  FEATURE_STATUS,
} from 'components/Providers/FeatureFlag/FeatureFlagProvider';

export const countriesWhereCreditAccountIsAvailable = ['GB'];
export const countriesWhereCreditAccountIsNotAvailable = ['US', 'CA', 'FR', 'DE', 'CH'];

// Refer to "Feature Provider, Context, and useFeatureFlag hook" section of README for more details on usage
export const featureFlagConfig = {
  monthlyStatements: createFeature({
    status: FEATURE_STATUS.All,
    rules: null,
  }),
  creditAccount: createFeature({
    status: FEATURE_STATUS.Development,
    rules: {
      excludeCountries: countriesWhereCreditAccountIsNotAvailable,
    },
  }),
  selfRegistrationV2: createFeature({
    status: FEATURE_STATUS.Development,
    rules: null,
  }),
};

export default featureFlagConfig;
