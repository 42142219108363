export const formatCorprateClientData = ({
  userAccountId,
  taxNumber,
  preferredLanguage,
  unitNumber,
  address1,
  address2,
  postalCode,
  jurisdiction,
  city,
  country,
}) => {
  return {
    userAccountId,
    taxNumber,
    preferredLanguage,
    address: {
      unitNumber,
      address1,
      address2,
      postalCode,
      jurisdiction,
      city,
      country,
    },
  };
};
