import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import { USER_REGISTRATION_ERRORS, USER_VERIFICATION_ERRORS } from 'constants/users';
import { useFeatureFlag } from 'components/Providers/FeatureFlag';
import notify from 'utils/notificationUtils';
import useAmplitude from 'utils/hooks/useAmplitude';
import useTranslation from 'utils/hooks/useTranslation';
import adminUsersApi from 'services/api/adminUsers.js';
import userActions from 'redux/user/actions';

export const useRegisterUser = () => {
  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();
  const dispatch = useDispatch();
  const { showFeature } = useFeatureFlag();

  const registerUser = async userInformation => {
    const result = showFeature('selfRegistrationV2')
      ? await adminUsersApi.registerV2(userInformation)
      : await adminUsersApi.register(userInformation);
    const data = result?.data;

    dispatch({
      type: userActions.SET_STATE,
      payload: {
        userId: data.userId,
      },
    });

    return data;
  };

  return useMutation(registerUser, {
    onSuccess: () => {
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationUserCreationSuccessful);
    },
    onError: ({ errors }) => {
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationUserCreationFailed);
      const domainErrorCode = errors?.domainErrorCode;
      if (
        domainErrorCode !== USER_REGISTRATION_ERRORS.DuplicateEmail &&
        domainErrorCode !== USER_REGISTRATION_ERRORS.UserEmailDomainNotValid
      ) {
        notify({
          type: 'error',
          message: translateText('corporateAccounts.user.registration.internalError'),
        });
      }
    },
  });
};

export const useVerifyUser = () => {
  const { sendAmplitudeEvent } = useAmplitude();
  const verifyUser = async verificationCode => {
    const result = await adminUsersApi.verify(verificationCode);
    return result?.data;
  };

  return useMutation(verifyUser, {
    onSuccess: () => {
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationUserVerificationSuccessful);
    },
    onError: ({ errors }) => {
      const domainErrorCode = errors?.domainErrorCode;
      if (domainErrorCode === USER_VERIFICATION_ERRORS.VerificationCodeExpired) {
        sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationUserVerificationCodeExpired);
      } else if (domainErrorCode === USER_VERIFICATION_ERRORS.VerificationCodeNotFound) {
        sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationUserVerificationCodeNotFound);
      } else {
        sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationUserVerificationFailed);
      }
    },
  });
};

export const useResendEmail = () => {
  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();

  const resendEmail = async ({ userId, preferredLanguage, recaptchaToken }) => {
    const result = await adminUsersApi.resendEmail({ userId, preferredLanguage, recaptchaToken });
    return result?.data;
  };

  return useMutation(resendEmail, {
    onSuccess: () => {
      notify({
        type: 'success',
        message: translateText('corporateAccounts.selfRegistration.resendEmail.success'),
      });

      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationResendVerificationEmail);
    },
    onError: () => {
      notify({
        type: 'error',
        message: translateText('corporateAccounts.selfRegistration.resendEmail.failed'),
      });

      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationResendVerificationEmailFailed);
    },
  });
};

export default {
  useRegisterUser,
  useVerifyUser,
  useResendEmail,
};
