import { ROUTES } from 'constants.js';
import { STAFF_USER_ROLES } from 'constants/staffUsers';
import { parseMaintenanceWindowEnv, showMaintenancePage } from 'utils/maintenanceUtil';

export const isUserSuperAdmin = adminRole => {
  if (!adminRole) {
    return false;
  }

  return adminRole === STAFF_USER_ROLES.pbp_super_admin.key;
};

export const shouldAttachMaintenanceRoute = (authorized, adminRole) => {
  const parsedMaintenanceDates = parseMaintenanceWindowEnv(
    process.env.REACT_APP_MAINTENANCE_WINDOW,
  );

  if (!parsedMaintenanceDates) {
    return false;
  }

  const [start, end] = parsedMaintenanceDates;
  return authorized && showMaintenancePage(start, end) && !isUserSuperAdmin(adminRole);
};

export const shouldAttachCorpRoutes = ({
  authorized,
  currentCorporation,
  restrictedCorporateClientProspect,
  emailNotVerified,
}) => {
  return (
    authorized && currentCorporation && !restrictedCorporateClientProspect && !emailNotVerified
  );
};

export const shouldAttachBusinessUserRoutes = ({
  authorized,
  restrictedCorporateClientProspect,
  emailNotVerified,
}) => {
  return authorized && !restrictedCorporateClientProspect && !emailNotVerified;
};

export const shouldAttachAdminRoutes = (authorized, adminRole) => {
  return authorized && STAFF_USER_ROLES[adminRole];
};

export const isRouteRestricted = (route, config, adminRole) => {
  const restrictions = {
    [ROUTES.PAYMENTS]: config?.paymentMethods?.isPageBlocked && !adminRole,
    [ROUTES.CREDIT_ACCOUNT]: config?.creditAccount?.isPageBlocked,
    [ROUTES.INVOICES]: config?.invoices?.isPageBlocked,
  };

  return restrictions[route.path] || false;
};

export default {
  isUserSuperAdmin,
  shouldAttachMaintenanceRoute,
  shouldAttachCorpRoutes,
  shouldAttachBusinessUserRoutes,
  shouldAttachAdminRoutes,
  isRouteRestricted,
};
