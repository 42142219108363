import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Checkbox, Form, Typography, Upload, Col } from 'antd';
import { CSVLink } from 'react-csv';
import { getCSVHeadersFromImportType } from 'components/Shared/BulkUpload/helper';
import { getDelimiterByCountry } from 'utils/exportUtil';
import ReCAPTCHA from 'react-google-recaptcha-enterprise';
import { DRIVERS_IMPORT, RECAPTCHA_SITE_KEY } from 'constants.js';
import styles from './style.module.scss';
import useTranslation from 'utils/hooks/useTranslation';
import { CORPORATE_CLIENT_COUNTRY_LOCALE } from 'constants/corporateClients';

const DriverImportModalContent = ({
  country,
  fileList,
  onExport,
  onFileUpload,
  setInvitationMethodSms,
  setInvitationMethodEmail,
  invitationMethodSms,
  invitationMethodEmail,
  generateToken,
  setRecaptchaToken,
}) => {
  const { translateText } = useTranslation(CORPORATE_CLIENT_COUNTRY_LOCALE[country]);
  const [form] = Form.useForm();

  const adminRole = useSelector(state => state.user?.adminRole);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  return (
    <div>
      <Typography.Text strong>
        {translateText('corporateAccounts.drivers.importModal.newDrivers')}
      </Typography.Text>
      <ul>
        <li>
          <CSVLink
            className={styles.csvTemplateLink}
            data={[]}
            filename={`${translateText('corporateAccounts.drivers.import.fileName', {
              override: true,
            })}.csv`}
            headers={getCSVHeadersFromImportType(DRIVERS_IMPORT, country, translateText)}
            separator={getDelimiterByCountry(country)}
          >
            {translateText('corporateAccounts.import.downloadCsvTemplate')}
          </CSVLink>
        </li>
        <li>
          <Typography.Text>
            {translateText('corporateAccounts.drivers.importModal.newDrivers.1')}
          </Typography.Text>
        </li>
      </ul>

      <Typography.Text strong>
        {translateText('corporateAccounts.drivers.importModal.editDrivers')}
      </Typography.Text>
      <ul>
        <li>
          <Typography.Link
            data-testid="exportDrivers"
            onClick={e => {
              e.preventDefault();
              onExport();
            }}
          >
            {translateText('corporateAccounts.drivers.importModal.editDrivers.1')}
          </Typography.Link>
        </li>
        <li>
          <Typography.Text>
            {translateText('corporateAccounts.drivers.importModal.editDrivers.2')}
          </Typography.Text>
        </li>
        <li>
          <Typography.Text>
            {translateText('corporateAccounts.drivers.importModal.editDrivers.3')}
          </Typography.Text>
        </li>
      </ul>

      <div className={styles.driverImportUploader}>
        <Button type="default" className={styles.uploadCSV}>
          <Upload
            data-testid="driverImportUploader"
            accept=".csv"
            beforeUpload={onFileUpload}
            fileList={fileList}
            showUploadList={false}
          >
            <span>
              {fileList.length
                ? translateText('corporateAccounts.actions.replaceFile')
                : translateText('corporateAccounts.actions.chooseCsv')}
            </span>
          </Upload>
        </Button>
        {!!fileList.length && <Typography.Text>{fileList[0].name}</Typography.Text>}
      </div>
      <div>
        {
          <Form form={form} layout="vertical" style={{ paddingTop: '25px' }}>
            <Form.Item
              name="invitationOptions"
              data-testid="invitationOptions"
              className={styles.menuItem_driverImportInvitationOptions}
              validateTrigger="onChange"
              label={translateText('corporateAccounts.drivers.form.invitationOptions')}
              rules={[
                {
                  validator: (_, value) => {
                    if ((value === undefined || value.length === 0) && !adminRole) {
                      return Promise.reject(
                        translateText('corporateAccounts.drivers.form.selectInvitationMethod'),
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Checkbox.Group>
                <Col>
                  <Form.Item
                    name="InvitationBySms"
                    initialValue={invitationMethodSms}
                    valuePropName="checked"
                  >
                    <Checkbox
                      data-testid="checkboxInvitationBySms"
                      value="InvitationBySms"
                      onChange={e => setInvitationMethodSms(e.target.checked)}
                    >
                      {translateText('corporateAccounts.drivers.form.sms')}
                    </Checkbox>
                  </Form.Item>

                  <Form.Item
                    name="InvitationByEmail"
                    initialValue={invitationMethodEmail}
                    valuePropName="checked"
                  >
                    <Checkbox
                      data-testid="checkboxInvitationByEmail"
                      value="InvitationByEmail"
                      onChange={e => setInvitationMethodEmail(e.target.checked)}
                    >
                      {translateText('corporateAccounts.drivers.email')}
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Checkbox.Group>
            </Form.Item>
            <div className={styles.recaptchaContainer}>
              <ReCAPTCHA
                data-testid="recaptcha"
                id="recaptcha"
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={generateToken}
                onExpired={() => setRecaptchaToken(null)}
              />
            </div>
          </Form>
        }
      </div>
    </div>
  );
};

export default DriverImportModalContent;
