import React from 'react';
import { Typography, Button } from 'antd';
import { CreditAccountDriverIcon } from 'components/Shared/Icons';
import useTranslation from 'utils/hooks/useTranslation';
import { CREDIT_ACCOUNT_FAQ_LINK } from 'constants/creditAccount.js';

import styles from './style.module.scss';

const { Title, Text } = Typography;

const CreditAccountOnboarding = ({ isRejectedStatus, setIsRedirectModalVisible }) => {
  const { translateText } = useTranslation();

  return (
    <>
      <section className={styles.topSection}>
        <span className={styles.titlesAndButton}>
          <Title className={styles.title}>
            {translateText('corporateAccounts.creditAccount.title')}
          </Title>
          <Title className={styles.subtitle}>
            {translateText('corporateAccounts.creditAccount.subtitle')}
          </Title>
          {!isRejectedStatus && (
            <Button
              type="primary"
              className={styles.setUpButton}
              onClick={() => setIsRedirectModalVisible(true)}
            >
              {translateText('corporateAccounts.creditAccount.setUpButton')}
            </Button>
          )}
        </span>
        <img
          src="/resources/images/credit_account_preview.png"
          alt="Credit account preview"
          className={styles.previewImage}
        />
      </section>

      <section className={styles.bottomSection}>
        <span className={styles.stepCards}>
          <span className={styles.stepCard}>
            <Text className={styles.stepCardTitle}>
              {translateText('corporateAccounts.creditAccount.stepCard1.title')}
            </Text>
            <Text className={styles.stepCardSubtitle}>
              {translateText('corporateAccounts.creditAccount.stepCard1.subtitle')}
            </Text>
          </span>
          <span className={styles.stepCard}>
            <Text className={styles.stepCardTitle}>
              {translateText('corporateAccounts.creditAccount.stepCard2.title')}
            </Text>
            <Text className={styles.stepCardSubtitle}>
              {translateText('corporateAccounts.creditAccount.stepCard2.subtitle')}
            </Text>
          </span>
          <span className={styles.stepCard}>
            <Text className={styles.stepCardTitle}>
              {translateText('corporateAccounts.creditAccount.stepCard3.title')}
            </Text>
            <Text className={styles.stepCardSubtitle}>
              {translateText('corporateAccounts.creditAccount.stepCard3.subtitle')}
            </Text>
          </span>
        </span>
        <span>
          <span className={styles.driversBlurb}>
            <CreditAccountDriverIcon />
            <Text className={styles.driversBlurbText}>
              {translateText('corporateAccounts.creditAccount.authorizedDriversBlurb')}
            </Text>
          </span>
          <span className={styles.faqLink}>
            <a href={CREDIT_ACCOUNT_FAQ_LINK} target="_blank" rel="noreferrer">
              {translateText('corporateAccounts.creditAccount.seeFaq')}
            </a>
          </span>
        </span>
      </section>
    </>
  );
};

export default CreditAccountOnboarding;
