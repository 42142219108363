export const FORM_FIELD_NAMES = {
  corporateClientName: 'corporateClientName',
  numberOfDrivers: 'numberOfDrivers',
  country: 'country',
  firstName: 'firstName',
  lastName: 'lastName',
  mobilePhoneNumber: 'mobilePhoneNumber',
  email: 'email',
  password: 'password',
  termsAndPrivacyAgreement: 'termsAndPrivacyAgreement',
};
