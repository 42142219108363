import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Authorize from 'components/Providers/Authorize';
import PageWrapper from 'components/Shared/Page/Wrapper';
import PageTitle from 'components/Shared/Page/Title';
import withPusher from 'components/Providers/Pusher/withPusher';
import PageBanners from 'components/Layout/PageBanners';
import useTranslation from 'utils/hooks/useTranslation';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import useAmplitude from '../../utils/hooks/useAmplitude';
import CreditAccountOnboarding from './CreditAccountOnboarding';
import CreditAccountDetails from './CreditAccountDetails';
import useCreditAccountPage from './useCreditAccountPage';
import Loader from 'components/Shared/Loader';
import CreditAccountPendingBanner from './CreditAccountPendingBanner';
import CreditAccountRejectedBanner from './CreditAccountRejectedBanner';
import CreditAccountRedirectModal from './CreditAccountRedirectModal';

import styles from './style.module.scss';

const CreditAccount = ({ history }) => {
  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();
  const { creditAccountMigrationConfig } = useSelector(state => state.user);
  const [isRedirectModalVisible, setIsRedirectModalVisible] = useState(false);
  const {
    creditAccountDetails,
    isCreditAccountLoading,
    isCreditAccountLoadingSuccessful,
  } = useCreditAccountPage();

  useEffect(() => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.creditAccountPage);
  }, [sendAmplitudeEvent]);

  const isRejectedStatus =
    isCreditAccountLoadingSuccessful &&
    creditAccountMigrationConfig?.creditAccount?.isRejectedBannerVisible;

  const isPendingStatus =
    isCreditAccountLoadingSuccessful &&
    creditAccountMigrationConfig?.creditAccount?.isPendingBannerVisible;

  return (
    <Authorize>
      <Helmet title="Credit account" />
      <PageBanners />
      <PageWrapper history={history} loading={isCreditAccountLoading}>
        {isPendingStatus && <CreditAccountPendingBanner />}
        {isRejectedStatus && <CreditAccountRejectedBanner />}
        <PageTitle
          title={translateText('corporateAccounts.creditAccount.pageTitle')}
          status={
            creditAccountDetails?.creditAccountStatus
              ? translateText(
                  `corporateAccounts.common.status.${creditAccountDetails?.creditAccountStatus}`,
                )
              : null
          }
        />
        <CreditAccountRedirectModal
          isModalVisible={isRedirectModalVisible}
          setIsModalVisible={setIsRedirectModalVisible}
        />
        <div className="card-body">
          <div className={styles.main}>
            {isCreditAccountLoadingSuccessful &&
            creditAccountDetails !== null &&
            !isRejectedStatus ? (
              <CreditAccountDetails
                creditAccountDetails={creditAccountDetails}
                isCreditAccountLoading={isCreditAccountLoadingSuccessful}
              />
            ) : isCreditAccountLoadingSuccessful ? (
              <CreditAccountOnboarding
                isRejectedStatus={isRejectedStatus}
                setIsRedirectModalVisible={setIsRedirectModalVisible}
              />
            ) : (
              <Loader spinning />
            )}
          </div>
        </div>
      </PageWrapper>
    </Authorize>
  );
};

export default withPusher(injectIntl(CreditAccount));
