import React from 'react';

const EmptyIconLarge = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="136"
    height="120"
    viewBox="0 0 136 120"
    fill="none"
    data-testid="emptyIconLarge"
  >
    <path
      d="M68.0001 119.779C105.443 119.779 135.797 114.107 135.797 107.111C135.797 100.115 105.443 94.443 68.0001 94.443C30.5569 94.443 0.203125 100.115 0.203125 107.111C0.203125 114.107 30.5569 119.779 68.0001 119.779Z"
      fill="#F5F5F7"
      fillOpacity="0.8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M122.238 69.895L98.3127 40.45C97.1647 39.064 95.4867 38.225 93.7197 38.225H42.2797C40.5137 38.225 38.8357 39.064 37.6877 40.45L13.7637 69.895V85.278H122.239V69.895H122.238Z"
      fill="#AEB8C2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.0332 0.221008H101.966C103.027 0.221008 104.044 0.642436 104.795 1.39258C105.545 2.14273 105.966 3.16014 105.966 4.22101V97.565C105.966 98.6259 105.545 99.6433 104.795 100.393C104.044 101.144 103.027 101.565 101.966 101.565H34.0332C32.9723 101.565 31.9549 101.144 31.2048 100.393C30.4546 99.6433 30.0332 98.6259 30.0332 97.565V4.22101C30.0332 3.16014 30.4546 2.14273 31.2048 1.39258C31.9549 0.642436 32.9723 0.221008 34.0332 0.221008Z"
      fill="#F5F5F7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.8807 10.174H93.1177C93.6482 10.174 94.1569 10.3847 94.5319 10.7598C94.907 11.1349 95.1177 11.6436 95.1177 12.174V37.131C95.1177 37.6614 94.907 38.1702 94.5319 38.5452C94.1569 38.9203 93.6482 39.131 93.1177 39.131H42.8807C42.3503 39.131 41.8416 38.9203 41.4665 38.5452C41.0914 38.1702 40.8807 37.6614 40.8807 37.131V12.174C40.8807 11.6436 41.0914 11.1349 41.4665 10.7598C41.8416 10.3847 42.3503 10.174 42.8807 10.174ZM43.1427 49.988H92.8557C93.4556 49.988 94.031 50.2263 94.4552 50.6505C94.8794 51.0747 95.1177 51.6501 95.1177 52.25C95.1177 52.8499 94.8794 53.4253 94.4552 53.8495C94.031 54.2737 93.4556 54.512 92.8557 54.512H43.1427C42.5428 54.512 41.9674 54.2737 41.5432 53.8495C41.119 53.4253 40.8807 52.8499 40.8807 52.25C40.8807 51.6501 41.119 51.0747 41.5432 50.6505C41.9674 50.2263 42.5428 49.988 43.1427 49.988ZM43.1427 61.751H92.8557C93.4558 61.751 94.0312 61.9894 94.4556 62.4137C94.8799 62.838 95.1182 63.4135 95.1182 64.0135C95.1182 64.6136 94.8799 65.189 94.4556 65.6133C94.0312 66.0376 93.4558 66.276 92.8557 66.276H43.1427C42.5427 66.276 41.9672 66.0376 41.5429 65.6133C41.1186 65.189 40.8802 64.6136 40.8802 64.0135C40.8802 63.4135 41.1186 62.838 41.5429 62.4137C41.9672 61.9894 42.5427 61.751 43.1427 61.751ZM122.016 105.253C121.241 108.324 118.519 110.613 115.281 110.613H20.7177C17.4797 110.613 14.7577 108.323 13.9837 105.253C13.8361 104.668 13.7615 104.067 13.7617 103.463V69.896H40.0797C42.9867 69.896 45.3297 72.344 45.3297 75.316V75.356C45.3297 78.327 47.6997 80.726 50.6067 80.726H85.3917C88.2987 80.726 90.6687 78.305 90.6687 75.333V75.321C90.6687 72.349 93.0117 69.895 95.9187 69.895H122.237V103.464C122.237 104.081 122.16 104.68 122.016 105.253Z"
      fill="#DCE0E6"
    />
  </svg>
);

export default EmptyIconLarge;
