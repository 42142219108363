import http, { httpWrapper } from 'services/http';
import userService from 'services/user';
import {
  ENDPOINT_CORPORATE_CLIENTS,
  ENDPOINT_DEACTIVATE,
  ENDPOINT_REACTIVATE,
  ENDPOINT_REGISTER,
  ENDPOINT_ACTIVATE,
  ENDPOINT_ONBOARDING,
  API_URL_CA_V2,
} from 'constants.js';
import { endpoint, endpointCorporateClient } from '.';

const read = async query => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'GET',
    url: `${endpoint(ENDPOINT_CORPORATE_CLIENTS, false)}`,
    headers: {
      ...authHeaders,
    },
    params: query,
  };

  const response = await httpWrapper(requestOptions);
  return response;
};

const readById = async id => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'GET',
    url: `${endpoint(ENDPOINT_CORPORATE_CLIENTS, false)}/${id}`,
    headers: {
      ...authHeaders,
    },
  };

  const response = await http(requestOptions).catch(err => console.log(err));
  return response;
};

const register = async data => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'POST',
    url: `${endpoint(ENDPOINT_CORPORATE_CLIENTS, false)}${ENDPOINT_REGISTER}`,
    headers: {
      ...authHeaders,
    },
    data,
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const registerV2 = async data => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'POST',
    url: `${endpoint(ENDPOINT_CORPORATE_CLIENTS, false, API_URL_CA_V2)}${ENDPOINT_REGISTER}`,
    headers: {
      ...authHeaders,
    },
    data,
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const registerCreditAccountCorporation = async data => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'POST',
    url: `${endpoint(
      ENDPOINT_CORPORATE_CLIENTS,
      false,
      API_URL_CA_V2,
    )}:registerCreditAccountClient`,
    headers: {
      ...authHeaders,
    },
    data,
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const activate = async data => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'POST',
    url: `${endpointCorporateClient(ENDPOINT_ONBOARDING, true)}${ENDPOINT_ACTIVATE}`,
    headers: {
      ...authHeaders,
    },
    data,
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const create = async data => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'POST',
    url: endpoint(ENDPOINT_CORPORATE_CLIENTS, false),
    headers: {
      ...authHeaders,
    },
    data,
  };

  const response = http(requestOptions);
  return response;
};

const update = async data => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'PUT',
    url: `${endpoint(ENDPOINT_CORPORATE_CLIENTS, false)}/${data.id}`,
    headers: {
      ...authHeaders,
    },
    data,
  };

  const response = http(requestOptions);
  return response;
};

const deactivate = async data => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'POST',
    url: `${endpoint(ENDPOINT_CORPORATE_CLIENTS, false)}/${data.id}${ENDPOINT_DEACTIVATE}`,
    headers: {
      ...authHeaders,
    },
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const reactivate = async data => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'POST',
    url: `${endpoint(ENDPOINT_CORPORATE_CLIENTS, false)}/${data.id}${ENDPOINT_REACTIVATE}`,
    headers: {
      ...authHeaders,
    },
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const destroy = async data => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'DELETE',
    url: `${endpoint(ENDPOINT_CORPORATE_CLIENTS, false)}/${data.id}`,
    headers: {
      ...authHeaders,
    },
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const onboardingProgress = async () => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'GET',
    url: `${endpointCorporateClient(ENDPOINT_ONBOARDING, true)}`,
    headers: {
      ...authHeaders,
    },
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const getBillingInformationById = async () => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'GET',
    url: `${endpointCorporateClient('/billing', true)}`,
    headers: {
      ...authHeaders,
    },
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const updateBillingInformationById = async billingInformation => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'PUT',
    url: `${endpointCorporateClient('/billing', true)}`,
    headers: {
      ...authHeaders,
    },
    data: billingInformation,
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const getPricePlan = async () => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'GET',
    url: `${endpointCorporateClient('/pricePlan', true)}`,
    headers: {
      ...authHeaders,
    },
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const migrateCorporationToCreditAccount = async payload => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'POST',
    url: `${endpointCorporateClient('/creditAccounts:migrate', false)}`,
    headers: {
      ...authHeaders,
    },
    data: payload,
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const corporateClients = {
  read,
  readById,
  register,
  registerV2,
  registerCreditAccountCorporation,
  activate,
  create,
  update,
  deactivate,
  reactivate,
  delete: destroy,
  onboardingProgress,
  getBillingInformationById,
  updateBillingInformationById,
  getPricePlan,
  migrateCorporationToCreditAccount,
};

export default corporateClients;
