import RegistrationForm from './RegistrationForm';
import RegistrationFormV2 from './RegistrationFormV2';
import RegistrationMarketing from './RegistrationMarketing';
import { useFeatureFlag } from 'components/Providers/FeatureFlag';

import styles from './style.module.scss';

const RegistrationPage = ({ isMobile, onRegister, isRegistrationLoading, registrationError }) => {
  const { showFeature } = useFeatureFlag();

  return (
    <>
      <RegistrationMarketing />
      <section id={styles.main} className={styles.content} data-testid="main">
        {showFeature('selfRegistrationV2') ? (
          <RegistrationFormV2
            isMobile={isMobile}
            onRegister={onRegister}
            isRegistrationLoading={isRegistrationLoading}
            registrationError={registrationError}
          />
        ) : (
          <RegistrationForm
            isMobile={isMobile}
            onRegister={onRegister}
            isRegistrationLoading={isRegistrationLoading}
            registrationError={registrationError}
          />
        )}
      </section>
    </>
  );
};

export default RegistrationPage;
