import React from 'react';
import { Button } from 'antd';
import { INVOICE_STATUS, INVOICE_STATUS_MAPPER } from 'constants/invoicesAndStatements';
import { DownloadOutlined } from '@ant-design/icons';
import { toCamelCase } from 'pages/CreditAccount/helper';

import styles from './style.module.scss';

const InvoiceStatusCell = ({ status, translateText }) => {
  return (
    <div className={`${styles.tag} ${styles[status]}`}>
      <span>{translateText(`corporateAccounts.invoicesAndStatements.status.${status}`)}</span>
    </div>
  );
};

const tableColumns = ({ handleInvoiceModalVisibility, translateText }) => [
  {
    title: translateText('corporateAccounts.invoicesAndStatements.billingDate'),
    dataIndex: 'billingDate',
    width: 180,
  },
  {
    title: translateText('corporateAccounts.invoicesAndStatements.totalAmount'),
    dataIndex: 'totalGross',
    width: 180,
  },
  {
    title: translateText('corporateAccounts.invoicesAndStatements.paymentDueDate'),
    dataIndex: 'paymentDueDate',
    width: 180,
  },
  {
    title: translateText('corporateAccounts.invoicesAndStatements.openAmount'),
    dataIndex: 'openingAmount',
    width: 180,
  },
  {
    title: translateText('corporateAccounts.invoicesAndStatements.invoiceStatus'),
    dataIndex: 'status',
    key: 'status',
    width: 180,
    filters: Object.values(INVOICE_STATUS).map(status => {
      return {
        text: translateText(`corporateAccounts.invoicesAndStatements.status.${status}`),
        value: status,
      };
    }),
    onFilter: (value, record) => {
      const mappedStatus = INVOICE_STATUS_MAPPER[record?.status.toUpperCase()];
      return mappedStatus === value;
    },
    render: status => {
      const mappedStatus = INVOICE_STATUS_MAPPER[status.toUpperCase()];
      return <InvoiceStatusCell status={toCamelCase(mappedStatus)} translateText={translateText} />;
    },
  },
  {
    title: translateText('corporateAccounts.invoicesAndStatements.downloadInvoice'),
    key: 'downloadinvoice',
    width: 180,
    render: (_text, record) => {
      return (
        <div>
          <Button
            className={styles.downloadButton}
            onClick={() => handleInvoiceModalVisibility({ invoiceId: record.invoiceId })}
            data-testid="downloadInvoiceTableButton"
          >
            {translateText('corporateAccounts.actions.download')}
            <span className={styles.downloadIcon}>
              <DownloadOutlined />
            </span>
          </Button>
        </div>
      );
    },
  },
];

export default tableColumns;
