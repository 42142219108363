import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';

export const DRIVER_EXPORT_CSV_HEADER_KEYS = [
  'firstName',
  'lastName',
  'email',
  'phoneCountryCode',
  'phoneNumber',
  'costCenter',
];

export const DRIVER_IMPORT_CSV_HEADER_KEYS = [
  'firstName',
  'lastName',
  'email',
  'countryCode',
  'phoneNumber',
  'costCenter',
];

export const DRIVER_STATUS = {
  NEW: 0,
  ACTIVE: 1,
  DELETED: 2,
  INVITE_SENT: 3,
  INVITE_EXPIRED: 4,
  INVITE_FAILED: 5,
  DEACTIVATED: 6,
};

export const DRIVER_STATUS_TAGS = {
  [DRIVER_STATUS.NEW]: {
    key: 'new',
    value: DRIVER_STATUS.NEW,
  },
  [DRIVER_STATUS.ACTIVE]: {
    key: 'active',
    value: DRIVER_STATUS.ACTIVE,
  },
  [DRIVER_STATUS.DELETED]: {
    key: 'deleted',
    value: DRIVER_STATUS.DELETED,
  },
  [DRIVER_STATUS.INVITE_SENT]: {
    key: 'inviteSent',
    value: DRIVER_STATUS.INVITE_SENT,
  },
  [DRIVER_STATUS.INVITE_EXPIRED]: {
    key: 'inviteExpired',
    value: DRIVER_STATUS.INVITE_EXPIRED,
  },
  [DRIVER_STATUS.INVITE_FAILED]: {
    key: 'inviteFailed',
    value: DRIVER_STATUS.INVITE_FAILED,
  },
  [DRIVER_STATUS.DEACTIVATED]: {
    key: 'deactivated',
    value: DRIVER_STATUS.DEACTIVATED,
  },
};

export const DRIVER_STATUS_FILTERS = [
  DRIVER_STATUS_TAGS[DRIVER_STATUS.NEW],
  DRIVER_STATUS_TAGS[DRIVER_STATUS.ACTIVE],
  DRIVER_STATUS_TAGS[DRIVER_STATUS.INVITE_SENT],
  DRIVER_STATUS_TAGS[DRIVER_STATUS.INVITE_EXPIRED],
  DRIVER_STATUS_TAGS[DRIVER_STATUS.INVITE_FAILED],
];

export const DRIVER_IMPORT_VALIDATION_ERRORS = {
  InvalidCostCenter: 'CsvRowValidationError:CostCenterInvalidFormat',
  CostCenterMaxLengthExceeded: 'CsvRowValidationError:CostCenterMaxLengthExceeded',
  InvalidEmail: 'CsvRowValidationError:EmailInvalidFormat',
  FirstNameRequired: 'CsvRowValidationError:FirstNameRequired',
  FirstNameMaxLengthExceeded: 'CsvRowValidationError:FirstNameMaxLengthExceeded',
  LastNameRequired: 'CsvRowValidationError:LastNameRequired',
  LastNameMaxLengthExceeded: 'CsvRowValidationError:LastNameMaxLengthExceeded',
  DuplicateDrivers: 'CsvRowValidationError:DuplicateDrivers',
  CsvNoRecord: 'CsvNoRecord',
  InvalidPhoneNumber: 'CsvRowValidationError:PhoneNumberInvalidFormat',
  PhoneNumberRequired: 'CsvRowValidationError:PhoneNumberRequired',
  EmailRequired: 'CsvRowValidationError:EmailRequired',
  UnknownParsingError: 'CsvRowValidationError:UnknownParsingError',
};

export const IMPORT_VALIDATION_ERRORS_TRANSLATION_MAP = {
  [DRIVER_IMPORT_VALIDATION_ERRORS.InvalidCostCenter]:
    'corporateAccounts.drivers.import.error.invalidCostCenter',
  [DRIVER_IMPORT_VALIDATION_ERRORS.CostCenterMaxLengthExceeded]:
    'corporateAccounts.drivers.import.error.costCenterMaxLengthExceeded',
  [DRIVER_IMPORT_VALIDATION_ERRORS.InvalidEmail]:
    'corporateAccounts.drivers.import.error.invalidEmail',
  [DRIVER_IMPORT_VALIDATION_ERRORS.FirstNameRequired]:
    'corporateAccounts.drivers.import.error.firstNameRequired',
  [DRIVER_IMPORT_VALIDATION_ERRORS.FirstNameMaxLengthExceeded]:
    'corporateAccounts.drivers.import.error.firstNameMaxLengthExceeded',
  [DRIVER_IMPORT_VALIDATION_ERRORS.LastNameRequired]:
    'corporateAccounts.drivers.import.error.lastNameRequired',
  [DRIVER_IMPORT_VALIDATION_ERRORS.LastNameMaxLengthExceeded]:
    'corporateAccounts.drivers.import.error.lastNameMaxLengthExceeded',
  [DRIVER_IMPORT_VALIDATION_ERRORS.DuplicateDrivers]:
    'corporateAccounts.drivers.import.error.duplicateEntries',
  [DRIVER_IMPORT_VALIDATION_ERRORS.CsvNoRecord]:
    'corporateAccounts.drivers.import.error.csvNoRecord',
  [DRIVER_IMPORT_VALIDATION_ERRORS.InvalidPhoneNumber]:
    'corporateAccounts.drivers.import.error.invalidNumbers',
  [DRIVER_IMPORT_VALIDATION_ERRORS.PhoneNumberRequired]:
    'corporateAccounts.drivers.import.error.phoneNumberRequired',
  [DRIVER_IMPORT_VALIDATION_ERRORS.EmailRequired]:
    'corporateAccounts.drivers.import.error.EmailRequired',
  [DRIVER_IMPORT_VALIDATION_ERRORS.UnknownParsingError]:
    'corporateAccounts.drivers.import.error.UnknownParsingError',
};

export const DRIVER_IMPORT_ERRORS = {
  InternalError: 'InternalError',
  UnexpectedException: 'UnexpectedException',
  DuplicateDriver: 'Driver already exists',
  RestrictedCorporateClientLimitExceeded: 'RestrictedCorporateClientLimitExceeded',
};

export const IMPORT_ERRORS_TRANSLATION_MAP = {
  [DRIVER_IMPORT_ERRORS.InternalError]: 'corporateAccounts.drivers.import.failed',
  [DRIVER_IMPORT_ERRORS.UnexpectedException]: 'corporateAccounts.drivers.import.failed',
  [DRIVER_IMPORT_ERRORS.DuplicateDriver]: 'corporateAccounts.driver.profileExists.error',
  [DRIVER_IMPORT_ERRORS.RestrictedCorporateClientLimitExceeded]:
    'corporateAccounts.driver.limitExceeded.error',
};

export const DRIVER_AMPLITUDE_EVENTS = {
  InternalError: AMPLITUDE_EVENT_TYPES.driverAddFailed,
  UnexpectedException: AMPLITUDE_EVENT_TYPES.driverAddFailed,
  DuplicateDriver: AMPLITUDE_EVENT_TYPES.driverAddErrorProfileAlreadyExists,
  RestrictedCorporateClientLimitExceeded: AMPLITUDE_EVENT_TYPES.driverAddLimitExceededError,
};

export const DRIVER_ONBOARDING_ERRORS = {
  InviteCodeNotValid: 'InviteCode',
  InviteCodeNotFound: 'InviteCodeNotFound',
  RegistrationRequired: 'RegistrationRequired',
  InvitationExpired: 'InvitationExpired',
  ActivationFailed: 'ActivationFailed',
  DriverNotFound: 'DriverNotFound',
  CorporationNotFound: 'CorporationNotFound',
  InternalError: 'InternalError',
  DriverAlreadyActive: 'DriverAlreadyActive',
  // PhoneNumber and Password will be added when displaying server validation data for the registration form
  // PhoneNumber: 'PhoneNumber',
  // PasswordComplexityNotMet: 'PasswordComplexityNotMet',
};

export const DRIVER_ONBOARDING_ERRORS_TRANSLATION_MAP = {
  [DRIVER_ONBOARDING_ERRORS.InviteCodeNotValid]: 'corporateAccounts.onboarding.inviteCodeNotValid',
  [DRIVER_ONBOARDING_ERRORS.InviteCodeNotFound]: 'corporateAccounts.onboarding.inviteCodeNotFound',
  [DRIVER_ONBOARDING_ERRORS.RegistrationRequired]:
    'corporateAccounts.onboarding.registrationRequired',
  [DRIVER_ONBOARDING_ERRORS.InvitationExpired]: 'corporateAccounts.onboarding.invitationExpired',
  [DRIVER_ONBOARDING_ERRORS.ActivationFailed]: 'corporateAccounts.onboarding.activationFailed',
  [DRIVER_ONBOARDING_ERRORS.DriverNotFound]: 'corporateAccounts.onboarding.driverNotFound',
  [DRIVER_ONBOARDING_ERRORS.CorporationNotFound]:
    'corporateAccounts.onboarding.corporationNotFound',
  [DRIVER_ONBOARDING_ERRORS.InternalError]: 'corporateAccounts.onboarding.internalError',
  [DRIVER_ONBOARDING_ERRORS.DriverAlreadyActive]:
    'corporateAccounts.onboarding.driverAlreadyActive',
  // [DRIVER_ONBOARDING_ERRORS.PhoneNumber]: 'corporateAccounts.onboarding.phoneNumber',
  // [DRIVER_ONBOARDING_ERRORS.PasswordComplexityNotMet]:
  //   'corporateAccounts.onboarding.PasswordComplexityNotMet',
};

export const DRIVER_ONBOARDING_ERRORS_AMPLITUDE_MAP = {
  [DRIVER_ONBOARDING_ERRORS.InviteCodeNotValid]: AMPLITUDE_EVENT_TYPES.onboardingInviteCodeNotValid,
  [DRIVER_ONBOARDING_ERRORS.InviteCodeNotFound]: AMPLITUDE_EVENT_TYPES.onboardingInviteCodeNotFound,
  [DRIVER_ONBOARDING_ERRORS.RegistrationRequired]:
    AMPLITUDE_EVENT_TYPES.onboardingRegistrationRequired,
  [DRIVER_ONBOARDING_ERRORS.InvitationExpired]: AMPLITUDE_EVENT_TYPES.onboardingInvitationExpired,
  [DRIVER_ONBOARDING_ERRORS.ActivationFailed]: AMPLITUDE_EVENT_TYPES.onboardingActivationFailed,
  [DRIVER_ONBOARDING_ERRORS.DriverNotFound]: AMPLITUDE_EVENT_TYPES.onboardingDriverNotFound,
  [DRIVER_ONBOARDING_ERRORS.CorporationNotFound]:
    AMPLITUDE_EVENT_TYPES.onboardingCorporationNotFound,
  [DRIVER_ONBOARDING_ERRORS.InternalError]: AMPLITUDE_EVENT_TYPES.onboardingInternalError,
  [DRIVER_ONBOARDING_ERRORS.DriverAlreadyActive]:
    AMPLITUDE_EVENT_TYPES.onboardingDriverAlreadyActive,
};

export default {
  DRIVER_EXPORT_CSV_HEADER_KEYS,
  DRIVER_STATUS,
  DRIVER_STATUS_TAGS,
  DRIVER_IMPORT_VALIDATION_ERRORS,
  IMPORT_VALIDATION_ERRORS_TRANSLATION_MAP,
  DRIVER_IMPORT_ERRORS,
  IMPORT_ERRORS_TRANSLATION_MAP,
};
