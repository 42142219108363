import React from 'react';
import flags from 'components/Shared/Icons/flags';

export const ROUTE_DEFAULT = '/drivers';
export const ROUTE_CORPORATIONS = '/corporations';
export const ROUTE_CORPORATIONS_ADMIN = '/admin/corporations';
export const ROUTE_CORPORATIONS_CREATE = '/admin/corporations/create';
export const ROUTE_CORPORATIONS_EDIT = '/admin/corporations/edit';
export const ROUTE_STAFF_USERS = '/admin/users';
export const ROUTE_DRIVERS = '/drivers';
export const ROUTE_DRIVERS_UPLOAD = '/drivers/upload';
export const ROUTE_VEHICLES = '/vehicles';
export const ROUTE_VEHICLES_UPLOAD = '/vehicles/upload';
export const ROUTE_PAYMENTS = '/payments';
export const ROUTE_SESSIONS = '/sessions';
export const ROUTE_MONTHLY_STATEMENTS = '/monthly-statements';
export const ROUTE_SPA_PAYMENT_CREATE = '/payments/create';
export const ROUTE_SPA_PAYMENT_EDIT = '/payments/edit';
export const ROUTE_AUTH_REDIRECT = '/auth/login_redirect';
export const ROUTE_AUTH_LOGOUT = '/auth/logout';
export const ROUTE_AUTH_LOGOUT_REDIRECT = '/auth/logout_redirect';
export const ROUTE_AUTH_SILENT_RENEW = '/auth/silent_renew';
export const ROUTE_ACTIVATE_REDIRECT = '/auth/activation_redirect';
export const ROUTE_404 = '/404';
export const ROUTE_REPORTS = '/reports';
export const ROUTE_ACTIVATE = '/activate';
export const ROUTE_USERS = '/users';
export const ROUTE_ONBOARDING = '/o';
export const ROUTE_REGISTRATION = '/registration';
export const ROUTE_VERIFY_EMAIL = '/verify_email';
export const ROUTE_BILLING = '/billing';
export const ROUTE_AUDIT_LOGS = '/audit_logs';
export const ROUTE_CREDIT_ACCOUNT = '/credit_account';
export const ROUTE_INVOICES = '/invoices';

export const ROUTES = {
  ADMIN_DEFAULT: ROUTE_CORPORATIONS_ADMIN,
  DEFAULT: ROUTE_DEFAULT,
  CORPORATIONS: ROUTE_CORPORATIONS,
  CORPORATIONS_ADMIN: ROUTE_CORPORATIONS_ADMIN,
  CORPORATIONS_CREATE: ROUTE_CORPORATIONS_CREATE,
  CORPORATIONS_EDIT: ROUTE_CORPORATIONS_EDIT,
  STAFF_USERS: ROUTE_STAFF_USERS,
  DRIVERS: ROUTE_DRIVERS,
  DRIVERS_UPLOAD: ROUTE_DRIVERS_UPLOAD,
  VEHICLES: ROUTE_VEHICLES,
  VEHICLES_UPLOAD: ROUTE_VEHICLES_UPLOAD,
  PAYMENTS: ROUTE_PAYMENTS,
  SESSIONS: ROUTE_SESSIONS,
  PAYMENTS_CREATE: ROUTE_SPA_PAYMENT_CREATE,
  PAYMENTS_EDIT: ROUTE_SPA_PAYMENT_EDIT,
  AUTH_REDIRECT: ROUTE_AUTH_REDIRECT,
  AUTH_LOGOUT: ROUTE_AUTH_LOGOUT,
  AUTH_LOGOUT_REDIRECT: ROUTE_AUTH_LOGOUT_REDIRECT,
  AUTH_SILENT_RENEW: ROUTE_AUTH_SILENT_RENEW,
  ACTIVATE_REDIRECT: ROUTE_ACTIVATE_REDIRECT,
  NOT_FOUND: ROUTE_404,
  REPORTS: ROUTE_REPORTS,
  ACTIVATE: ROUTE_ACTIVATE,
  USERS: ROUTE_USERS,
  ONBOARDING: ROUTE_ONBOARDING,
  MONTHLY_STATEMENTS: ROUTE_MONTHLY_STATEMENTS,
  REGISTRATION: ROUTE_REGISTRATION,
  VERIFY_EMAIL: ROUTE_VERIFY_EMAIL,
  BILLING: ROUTE_BILLING,
  AUDIT_LOGS: ROUTE_AUDIT_LOGS,
  CREDIT_ACCOUNT: ROUTE_CREDIT_ACCOUNT,
  INVOICES: ROUTE_INVOICES,
};

export const AMPLITUDE_KEY = process.env.REACT_APP_AMPLITUDE_KEY;
export const RAYGUN_KEY = process.env.REACT_APP_API_KEY_RAYGUN;

export const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID;
export const HOTJAR_SNIPPET_VERSION = process.env.REACT_APP_HOTJAR_SNIPPET_VERSION;

export const LOCALE_STORE_URL = process.env.REACT_APP_LOCALE_STORE_URL;
export const ENABLE_PLAUSIBLE = process.env.REACT_APP_ENABLE_PLAUSIBLE;
export const API_URL_IDA_AUTH = process.env.REACT_APP_API_URL_IDA_AUTH;
export const URL_BUSINESS_PORTAL = process.env.REACT_APP_API_URL_BUSINESS_PORTAL;
export const API_URL_CA = process.env.REACT_APP_API_URL_CA;
export const API_URL_CA_V2 = process.env.REACT_APP_API_URL_CA_V2;
export const API_URL_REPORTS = process.env.REACT_APP_API_URL_REPORTS;
export const API_URL_REPORTS_V1 = process.env.REACT_APP_API_URL_REPORTS_V1;
export const API_URL_DRIVERS_V1 = process.env.REACT_APP_API_URL_DRIVERS_V1;
export const API_URL_EVENTS = process.env.REACT_APP_API_URL_EVENTS;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
export const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
export const PORTAL_ENV = process.env.REACT_APP_ENV;
export const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY;
export const ZENDESK_SUPPORTED_COUNTRIES = process.env.REACT_APP_ZENDESK_SUPPORTED_COUNTRIES;
export const APP_MAINTENANCE_WINDOW = process.env.REACT_APP_MAINTENANCE_WINDOW;
export const APPLE_SIGN_IN_CLIENT_ID = process.env.REACT_APP_APPLE_SIGN_IN_CLIENT_ID;
export const SOCIAL_SIGN_IN_REDIRECT_URL = process.env.REACT_APP_SOCIAL_SIGN_IN_REDIRECT_URL;
export const FACEBOOK_SIGN_IN_APP_ID = process.env.REACT_APP_FACEBOOK_SIGN_IN_APP_ID;
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
export const API_URL_CORE = process.env.REACT_APP_API_URL_APIGEE_CORE;
export const API_URL_CORE_V2 = process.env.REACT_APP_API_URL_APIGEE_CORE_V2;
export const API_URL_CAS = process.env.REACT_APP_API_URL_APIGEE_CAS;
export const ALLSTAR_CREDIT_ACCOUNT_URL = process.env.REACT_APP_ALLSTAR_CREDIT_ACCOUNT_URL;
export const API_KEY_CAS = process.env.REACT_APP_API_KEY_CAS;
export const PARKING_WEB_APP_LOGIN_URL = process.env.REACT_APP_PARKING_WEB_APP_LOGIN_URL;
export const getMaximumPaymentAccounts = () => {
  return parseInt(process.env.REACT_APP_MAXIMUM_PAYMENT_ACCOUNTS);
};

// PAS 2.0
export const API_URL_PAS2 = process.env.REACT_APP_API_URL_PAS2;
export const API_KEY_PAS2 = process.env.REACT_APP_API_KEY_PAS2;

export const ENDPOINT_ADMINS = '/useraccounts';
export const ENDPOINT_USERS = '/users';
export const ENDPOINT_USER_PROFILES = '/useraccounts/profiles';
export const ENDPOINT_ADMINS_INVITE = '/invitations/admin';
export const ENDPOINT_CORPORATE_CLIENTS = '/corporateClients';
export const ENDPOINT_DRIVERS = '/parkers';
export const ENDPOINT_DRIVERSV2 = '/drivers';
export const ENDPOINT_PARKING_SESSIONS = '/parkingsessions';
export const ENDPOINT_PAYMENTS = '/payments';
export const ENDPOINT_PAYMENT_METHODS = '/paymentmethods';
export const ENDPOINT_REPORTS = '/reports';
export const ENDPOINT_REPORT_JOB = '/reportJobs';
export const ENDPOINT_SESSIONS = '/sessions';
export const ENDPOINT_VEHICLES = '/vehicles';
export const ENDPOINT_ADMIN_API_CONFIGURATION = '/configuration';
export const ENDPOINT_ONBOARDING = '/onboarding';
export const ENDPOINT_DEACTIVATE = ':deactivate';
export const ENDPOINT_REACTIVATE = ':reactivate';
export const ENDPOINT_ACTIVATE = ':activate';
export const ENDPOINT_REGISTER = ':register';
export const ENDPOINT_VERIFY = ':verify';
export const ENDPOINT_SEND_VERIFICATION = ':sendVerification';
export const ENDPOINT_COMPARE = ':compare';

// Import endpoints
export const ENDPOINT_DRIVERS_IMPORT = '/import/parkers';
export const ENDPOINT_VEHICLES_IMPORT = '/import/vehicles';

export const ENDPOINT_SERVICES_GET = '/services/fr';
export const ENDPOINT_SERVICES_POST = '/subscriptions';

export const ENDPOINT_AUDIT_LOGS = '/auditLogs';

// Import type
export const DRIVERS_IMPORT = 'drivers';
export const VEHICLES_IMPORT = 'vehicles';

// Permission Roles
export const PBP_SUPER_ADMIN = 'pbp_super_admin';
export const PRIMARY_FLEET_MANAGER = 'primary_fleet_manager';
export const FLEET_MANAGER = 'fleet_manager';
export const SERVICE_ADMIN = 'service_admin';
export const REPORTING = 'reporting';
export const PBP_CS_TIER_2 = 'pbp_tier_2_customer_support_corpaccts';
export const PBP_CUSTOMER_SUCCESS = 'pbp_customer_success';

export const SESSION_EXPIRY_TIME = process.env.REACT_APP_SESSION_EXPIRY_TIME;
export const SPA_POLLING_INTERVAL = process.env.REACT_APP_SPA_POLLING_INTERVAL;
export const SPA_MAXIMUM_POLLING_ATTEMPTS = process.env.REACT_APP_SPA_MAXIMUM_POLLING_ATTEMPTS;

export const ONETRUST_DATA_DOMAIN_SCRIPT = process.env.REACT_APP_ONETRUST_DATA_DOMAIN_SCRIPT;

export const VEHICLE_STATUS = {
  0: {
    label: 'New',
    key: 'new',
  },
  1: {
    label: 'Active',
    key: 'active',
  },
  2: {
    label: 'Passive',
    key: 'passive',
  },
  3: {
    label: 'Deleted',
    key: 'deleted',
  },
};

export const VEHICLE_TYPE = [
  {
    label: 'Car',
    key: 'car',
    value: 1,
  },
  {
    label: 'Motorcycle',
    key: 'motorcycle',
    value: 2,
  },
  {
    label: 'Electric Motorcycle',
    key: 'electricMotorcycle',
    value: 3,
  },
  {
    label: 'Heavy Goods Vehicle',
    key: 'heavyGoodsVehicle',
    value: 4,
  },
];

export const GLOBAL_LOCALE = 'glb-glb';

export const COUNTRIES = {
  AT: {
    label: 'Austria',
    countryCode: 'AT',
    callingCode: '+43',
    icon: <>{flags.Austria()}</>,
  },
  AL: {
    label: 'Albania',
    countryCode: 'AL',
  },
  AM: {
    label: 'Armenia',
    countryCode: 'AM',
  },
  AD: {
    label: 'Andorra',
    countryCode: 'AD',
  },
  BE: {
    label: 'Belgium',
    countryCode: 'BE',
    callingCode: '+32',
    icon: <>{flags.Belgium()}</>,
    locale: 'nl-BE',
    language: 'Dutch (Belgium)',
  },
  BG: {
    label: 'Bulgaria',
    countryCode: 'BG',
  },
  BA: {
    label: 'Bosnia and Herzegovina',
    countryCode: 'BA',
  },
  BY: {
    label: 'Belarus',
    countryCode: 'BY',
  },
  CA: {
    label: 'Canada',
    countryCode: 'CA',
    callingCode: '+1',
    icon: <>{flags.Canada()}</>,
  },
  HR: {
    label: 'Croatia',
    countryCode: 'HR',
  },
  CY: {
    label: 'Cyprus',
    countryCode: 'CY',
  },
  CZ: {
    label: 'Czech Republic',
    countryCode: 'CZ',
  },
  DK: {
    label: 'Denmark',
    countryCode: 'DK',
  },
  EE: {
    label: 'Estonia',
    countryCode: 'EE',
  },
  FI: {
    label: 'Finland',
    countryCode: 'FI',
  },
  FR: {
    label: 'France',
    countryCode: 'FR',
    callingCode: '+33',
    icon: <>{flags.France()}</>,
    locale: 'fr-FR',
    language: 'French',
  },
  DE: {
    label: 'Germany',
    countryCode: 'DE',
    callingCode: '+49',
    icon: <>{flags.Germany()}</>,
    locale: 'de-DE',
    language: 'German',
  },
  GR: {
    label: 'Greece',
    countryCode: 'GR',
  },
  HU: {
    label: 'Hungary',
    countryCode: 'HU',
  },
  IS: {
    label: 'Iceland',
    countryCode: 'IS',
  },
  IE: {
    label: 'Ireland',
    countryCode: 'IE',
  },
  IT: {
    label: 'Italy',
    countryCode: 'IT',
    callingCode: '+39',
    icon: <>{flags.Italy()}</>,
    locale: 'it-IT',
    language: 'Italian',
  },
  LV: {
    label: 'Latvia',
    countryCode: 'LV',
  },
  LI: {
    label: 'Liechtenstein',
    countryCode: 'LI',
  },
  LT: {
    label: 'Lithuania',
    countryCode: 'LT',
  },
  MT: {
    label: 'Malta',
    countryCode: 'MT',
  },
  MD: {
    label: 'Moldova',
    countryCode: 'MD',
  },
  MC: {
    label: 'Monaco',
    countryCode: 'MC',
    callingCode: '+377',
    icon: <>{flags.Monaco()}</>,
  },
  ME: {
    label: 'Montenegro',
    countryCode: 'ME',
  },
  NL: {
    label: 'Netherlands',
    countryCode: 'NL',
    callingCode: '+31',
    icon: <>{flags.Netherlands()}</>,
    locale: 'nl-BE',
    language: 'Dutch',
  },
  MK: {
    label: 'North Macedonia',
    countryCode: 'MK',
  },
  NO: {
    label: 'Norway',
    countryCode: 'NO',
  },
  PL: {
    label: 'Poland',
    countryCode: 'PL',
  },
  PT: {
    label: 'Portugal',
    countryCode: 'PT',
  },
  RO: {
    label: 'Romania',
    countryCode: 'RO',
  },
  RU: {
    label: 'Russia',
    countryCode: 'RU',
  },
  SM: {
    label: 'San Marino',
    countryCode: 'SM',
  },
  RS: {
    label: 'Serbia',
    countryCode: 'RS',
  },
  SK: {
    label: 'Slovakia',
    countryCode: 'SK',
  },
  I: {
    label: 'Slovenia',
    countryCode: 'I',
  },
  ES: {
    label: 'Spain',
    countryCode: 'ES',
    callingCode: '+34',
    icon: <>{flags.Spain()}</>,
  },
  SE: {
    label: 'Sweden',
    countryCode: 'SE',
  },
  CH: {
    label: 'Switzerland',
    countryCode: 'CH',
    callingCode: '+41',
    icon: <>{flags.Switzerland()}</>,
    locale: 'de-DE',
    language: 'German',
  },
  TR: {
    label: 'Turkey',
    countryCode: 'TR',
  },
  UA: {
    label: 'Ukraine',
    countryCode: 'UA',
  },
  VA: {
    label: 'Vatican',
    countryCode: 'VA',
  },
  GB: {
    label: 'United Kingdom',
    countryCode: 'GB',
    callingCode: '+44',
    icon: <>{flags.UnitedKingdom()}</>,
    locale: 'en-GB',
    language: 'English (GB)',
  },
  US: {
    label: 'United States',
    countryCode: 'US',
    callingCode: '+1',
    icon: <>{flags.UnitedStates()}</>,
    locale: 'en-US',
    language: 'English',
  },
};

export const LOCALE_TO_COUNTRY = {
  'de-DE': COUNTRIES.DE,
  'en-GB': COUNTRIES.GB,
  'en-US': COUNTRIES.US,
  'fr-FR': COUNTRIES.FR,
};

export const SUPPORTED_COUNTRIES = [
  COUNTRIES.CA,
  COUNTRIES.FR,
  COUNTRIES.DE,
  COUNTRIES.CH,
  COUNTRIES.GB,
  COUNTRIES.US,
];

export const LANGUAGES = [
  {
    label: 'English (Canada)',
    value: 'en-CA',
  },
  {
    label: 'English (United States)',
    value: 'en-US',
  },
  {
    label: 'English (United Kingdom)',
    value: 'en-GB',
  },
  {
    label: 'French (Standard)',
    value: 'fr-FR',
  },
  {
    label: 'German (Standard)',
    value: 'de-DE',
  },
];

export const BROWSER_LANGUAGES_MAPPING = {
  en: 'en-US',
  'en-AU': 'en-US',
  'en-BZ': 'en-US',
  'en-CA': 'en-US',
  'en-CB': 'en-US',
  'en-IE': 'en-US',
  'en-JM': 'en-US',
  'en-NZ': 'en-US',
  'en-PH': 'en-US',
  'en-TT': 'en-US',
  'en-US': 'en-US',
  'en-IN': 'en-US',
  'en-ZA': 'en-US',
  'en-GB-oxendict': 'en-GB',
  'en-GB': 'en-GB',
  fr: 'fr-FR',
  'fr-BE': 'fr-FR',
  'fr-CA': 'fr-FR',
  'fr-CH': 'fr-FR',
  'fr-FR': 'fr-FR',
  'fr-LU': 'fr-FR',
  'fr-MC': 'fr-FR',
  de: 'de-DE',
  'de-AT': 'de-DE',
  'de-CH': 'de-DE',
  'de-DE': 'de-DE',
  'de-LI': 'de-DE',
  'de-LU': 'de-DE',
};

export const USER_ROLES = [
  {
    label: 'Account Owner',
    value: 'primary_fleet_manager',
  },
  {
    label: 'Business Manager',
    value: 'fleet_manager',
  },
  {
    label: 'Business Administrator (Admin)',
    value: 'service_admin',
  },
  {
    label: 'Analyst',
    value: 'reporting',
  },
];

export const CURRENCY = {
  CA: '$',
  GB: '£',
  FR: '€',
};

export const INFO_MESSAGES = {
  vehicles: {
    cards: [
      {
        title: 'ADD A VEHICLE',
        body:
          'Enter a vehicles into the portal so that your drivers can park them using their PayByPhone app.',
        image: 'vehicles_one.svg',
      },
      {
        title: 'SET VEHICLE NAME',
        body:
          'Give your vehicle a name. This will not be displayed in the app, but will help you when filtering your list.',
        image: 'vehicles_two.svg',
      },
      {
        title: 'START PARKING',
        body:
          'Once you have saved the vehicle, your drivers will be able to search for and add it to their app profile.',
        image: 'vehicles_three.svg',
      },
    ],
    note:
      'Your drivers will be able to only add corporate vehicles that are entered into this list. Vehicles cannot be linked to a specific driver with the portal.',
  },
  payments: {
    cards: [
      {
        title: 'ADD A PAYMENT CARD',
        body:
          'Make it easier to manage parking payments by sharing a masked corporate payment card with your drivers.',
        image: 'payments_one.svg',
      },
      {
        title: 'SET CARD NAME',
        body:
          'Give your payment card a name so that your employees can easily recognise it in the app.',
        image: 'payments_two.svg',
      },
      {
        title: 'START PARKING',
        body: 'Once you have saved your card, it will be accessible on your drivers device',
        image: 'payments_three.svg',
      },
    ],
    note:
      'Your drivers will only be able to see the name of your card. They will not require any further card information or CVV in order to pay for parking.',
  },
  drivers: {
    cards: [
      {
        title: 'ADD A DRIVER',
        body:
          "Once you have a payent method and vehicles added. It's time to enter your drivers details into the portal.",
        image: 'drivers_one.svg',
      },
      {
        title: 'SEND INVITATION',
        body: 'Choose to send your drivers an automatic invitation. View Invitation Process',
        image: 'drivers_two.svg',
      },
      {
        title: 'START PARKING',
        body:
          "Once your drivers' account is linked, they will be able to pay for business parking with your corporate payment information.",
        image: 'drivers_three.svg',
      },
    ],
    note:
      'It is important that your drivers link their corporation account to their personal account. As setting up a separate business account will cause unnecessary time and friction during the parking experience.',
  },
};

export const ADMIN_USER_INVITE_STATUS = [
  {
    value: 'Created',
    key: 'notInvited',
  },
  {
    value: 'inviteSent',
    key: 'inviteSent',
  },
  {
    value: 'active',
    key: 'active',
  },
  {
    value: 'deleted',
    key: 'deleted',
  },
  {
    value: 'InvitationFailed',
    key: 'inviteFailed',
  },
  {
    value: 'inviteExpired',
    key: 'inviteExpired',
  },
];

// This object has to be deprecated from the codebase, use USER_ROLES instead
export const ADMIN_USER_ROLES = {
  primary_fleet_manager: {
    label: 'Account Owner',
    value: 'primary_fleet_manager',
    key: 'primary_fleet_manager',
  },
  fleet_manager: {
    label: 'Business Manager',
    value: 'fleet_manager',
    key: 'fleet_manager',
  },
  service_admin: {
    label: 'Business Administrator (Admin)',
    value: 'service_admin',
    key: 'service_admin',
  },
  reporting: {
    label: 'Analyst',
    value: 'reporting',
    key: 'reporting',
  },
  pbp_customer_success: {
    label: 'PBP Customer Success',
    value: 'pbp_customer_success',
    key: 'pbp_customer_success',
    weight: 1,
  },
  pbp_tier_2_customer_support_corpaccts: {
    label: 'PBP Customer Support Tier 2',
    value: 'pbp_tier_2_customer_support_corpaccts',
    key: 'pbp_tier_2_customer_support_corpaccts',
    weight: 2,
  },
  pbp_super_admin: {
    label: 'PBP Super Admin',
    value: 'pbp_super_admin',
    key: 'pbp_super_admin',
    weight: 0,
  },
};

export const MAXIMUM_CHAR_COUNT = {
  max: 75,
  message: 'Field has exceeded maximum length of 75 characters',
};

export const PREFERRED_COUNTRIES = [
  'us',
  'ca',
  'gb',
  'fr',
  'be',
  'mc',
  'au',
  'ch',
  'nl',
  'de',
  'at',
];

export const DAYS_OF_WEEK = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 0,
};

// Max supported upload size for reports import is 1MB due to
// invocation payload limits in aws lambda. More details in comments in
// https://corp.paybyphone.com/jira/browse/CA-516
export const MAX_UPLOAD_SIZE_BYTES = 1000000;

export const ERROR_MESSAGES = {
  InternalServerError: 'An internal server error occurred, try again later.',
};

export const TERMS_AND_CONDITIONS_URL = {
  en: 'https://www.paybyphone.com/legal/terms/en',
  fr: 'https://www.paybyphone.com/legal/terms/fr',
  nl: 'https://www.paybyphone.com/legal/terms/nl',
  cn: 'https://www.paybyphone.com/legal/terms/cn',
  tw: 'https://www.paybyphone.com/legal/terms/tw',
  pa: 'https://www.paybyphone.com/legal/terms/pa',
  it: 'https://www.paybyphone.com/legal/terms/it',
  de: 'https://www.paybyphone.com/legal/terms/de',
  es: 'https://www.paybyphone.com/legal/terms/es',
};

export const PRIVACY_URL = {
  en: 'https://www.paybyphone.com/legal/privacy/en',
  fr: 'https://www.paybyphone.com/legal/privacy/fr',
  nl: 'https://www.paybyphone.com/legal/privacy/nl',
  cn: 'https://www.paybyphone.com/legal/privacy/cn',
  tw: 'https://www.paybyphone.com/legal/privacy/tw',
  pa: 'https://www.paybyphone.com/legal/privacy/pa',
  it: 'https://www.paybyphone.com/legal/privacy/it',
  de: 'https://www.paybyphone.com/legal/privacy/de',
  es: 'https://www.paybyphone.com/legal/privacy/es',
};

export const BUSINESS_SUBSCRIPTION_AGREEMENT_URL = {
  en: 'https://www.paybyphone.com/legal/business/en',
  fr: 'https://www.paybyphone.com/legal/business/fr',
};

export const STORED_CREDENTIAL_AGREEMENT_URL = {
  en: 'https://www.paybyphone.com/legal/stored-credentials/en',
  fr: 'https://www.paybyphone.com/legal/stored-credentials/fr',
};

export const LOGIN_URL = {
  Deep_Link: 'https://paybyphone.onelink.me/ZQkh/8jwvlbvx',
  Web: PARKING_WEB_APP_LOGIN_URL,
};

export const RELEASE_VERSION = process.env.REACT_APP_RELEASE_VERSION;
export const BUILD_VERSION = process.env.REACT_APP_BUILD_VERSION;

export const RESTRICTED_CORPORATE_CLIENT_PROSPECT = 'RestrictedCorporateClientProspect';
export const EMAIL_NOT_VERIFIED = 'EmailNotVerified';
export const PLACEHOLDER_CORPORATE_CLIENT_PROSPECT_LIST = [
  {
    id: RESTRICTED_CORPORATE_CLIENT_PROSPECT,
    corporateClientName: 'Onboarding',
    preferredLanguage: 'en-CA',
    address: {
      city: 'Onboarding City',
    },
    country: '',
    status: 4,
    statusName: 'Prospect',
    parkingPaymentType: 0,
    accountOwnerStatus: 0,
    accountOwnerStatusName: 'Created',
    spaCount: 0,
    isTestCorporation: false,
    createdAt: '2023-07-11T12:51:14.403000+00:00',
  },
];

export const TEMP_GET_CORPORATE_CLIENT_PROSPECT_RESPONSE = {
  filterBy: null,
  items: [],
  limit: 20,
  offset: 0,
  orderBy: '',
  totalCount: 1,
};

export const CORPORATE_CLIENT_PROSPECT_STATUS = 'Prospect';
export const CORPORATE_CLIENT_ACTIVE_STATUS = 'Active';
export const CORPORATE_CLIENT_DEACTIVATED_STATUS = 'Deactivated';

export const COUNTRY_LINKS = {
  US: {
    subscriptionAgreement: 'https://www.paybyphone.com/legal-pages/terms-conditions-business',
    privacyPolicy: 'https://www.paybyphone.com/legal-pages/privacy-policy',
    contactSales: 'https://www2.paybyphone.com/fleets/contact-us',
    contactSupport: 'https://pbpbusiness.zendesk.com/hc/en-gb/requests/new',
  },
  GB: {
    subscriptionAgreement: 'https://www.paybyphone.co.uk/legal-pages/pbpb-terms-conditions',
    privacyPolicy: 'https://www.paybyphone.co.uk/legal-pages/privacy-policy',
    contactSales:
      'https://www2.paybyphone.co.uk/fleets/contact-us?utm_source=contact-sales&utm_medium=button&utm_campaign=self-registration',
    contactSupport: 'https://pbpbusiness.zendesk.com/hc/en-gb/requests/new',
  },
  FR: {
    subscriptionAgreement:
      'https://www.paybyphone.fr/entreprises/conditions-generales-paybyphone-business',
    privacyPolicy: 'https://www.paybyphone.fr/informations-legales/politique-de-confidentialite',
    contactSales:
      'https://www.paybyphone.fr/entreprises/contactez-nous?utm_source=contact-sales&utm_medium=button&utm_campaign=self-registration',
    contactSupport: 'https://pbpbusiness.zendesk.com/hc/fr-fr/requests/new',
  },
  DE: {
    subscriptionAgreement: 'https://www.paybyphone.de/paybyphone-business-agb',
    privacyPolicy: 'https://www.paybyphone.de/datenschutz',
    contactSales: 'https://www.paybyphone.de/contact-paybyphone-business',
    contactSupport: 'https://pbpbusiness.zendesk.com/hc/de-de/requests/new',
  },
};
