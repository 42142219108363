import React, { useState } from 'react';
import { Form, Row, Col, Button, Typography, Select } from 'antd';
import { injectIntl } from 'react-intl';
import { isPostalCode } from 'validator';
import { getJurisdictionLabel, getTaxNumberLabel } from 'utils/region';
import useTranslation from 'utils/hooks/useTranslation';
import useAmplitude from 'utils/hooks/useAmplitude';
import { MAXIMUM_CHAR_COUNT } from 'utils/formUtils/validateCharCountUtil.js';
import { validateTaxNumber } from 'utils/taxNumberUtil';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import { TAX_NUMBER_REQUIRED_COUNTRIES } from 'regions';
import useIsFormValid from 'utils/hooks/useIsFormValid';
import InputWithCheckmarkValidation from 'components/Shared/Page/InputWithCheckmarkValidation';
import { REGION_CODES, JURISDICTION_REQUIRED_COUNTRIES } from 'regions';

import styles from './style.module.scss';

const { Title } = Typography;
const { Option } = Select;
const { Item } = Form;

const CorporateClientRegistrationFormV2 = ({
  isMobile,
  isLoading,
  onSubmit,
  countryCode = 'CA',
}) => {
  const isTaxNumberRequired = TAX_NUMBER_REQUIRED_COUNTRIES.includes(countryCode);
  const fieldValidationConfig = [
    { name: 'country', required: false },
    { name: 'address1', required: true },
    { name: 'address2', required: false },
    { name: 'jurisdiction', required: JURISDICTION_REQUIRED_COUNTRIES.includes(countryCode) },
    { name: 'city', required: true },
    { name: 'postalCode', required: true },
    { name: 'taxNumber', required: isTaxNumberRequired },
  ];

  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();
  const [form] = Form.useForm();
  const { validateFields } = form;
  const { isFormValid, validateForm } = useIsFormValid(form, fieldValidationConfig);

  const [isAddress1Valid, setIsAddress1Valid] = useState(false);
  const [isAddress2Valid, setIsAddress2Valid] = useState(false);
  const [isCityValid, setIsCityValid] = useState(false);
  const [isPostalCodeValid, setIsPostalCodeValid] = useState(false);
  const [isTaxNumberValid, setIsTaxNumberValid] = useState(false);

  const taxNumberLabel = getTaxNumberLabel(countryCode, translateText);

  const characterCountExceededMessage = translateText(
    'corporateAccounts.common.error.characterCountExceeded',
  );
  const formItemSize = 24;
  const responsiveColSpan = isMobile ? formItemSize : 12;
  const layout = {
    labelCol: { span: 24 },
  };

  const handleSubmit = async () => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationRegisterCorporationButton);

    try {
      const formData = await validateFields();
      onSubmit({ formData });
    } catch (error) {}
  };

  const handleFieldChange = (fieldName, setValidationState) => {
    validateFields([fieldName])
      .then(() => {
        setValidationState(true);
      })
      .catch(() => {
        setValidationState(false);
      });
  };

  const postalCodeValidator = countryCode => (_, value) => {
    if (!value || isPostalCode(value, countryCode)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Invalid postal code'));
  };

  return (
    <div className={styles.form} data-testid="CorporateClientRegistrationFormV2">
      <Form form={form} {...layout} onFieldsChange={validateForm} requiredMark="optional">
        <Row gutter={formItemSize}>
          <Col span={formItemSize} className={styles.registerHeader}>
            <Title level={2}>
              {translateText('corporateAccounts.corporations.register.title')}
            </Title>
          </Col>
        </Row>
        <Title level={4} className={styles.addressTitle}>
          {translateText('corporateAccounts.corporations.register.addressTitle')}
        </Title>
        <Row gutter={formItemSize}>
          <Col span={formItemSize}>
            <Item
              name={'country'}
              label={translateText('corporateAccounts.corporateClient.country')}
              required={true} // This field is prefilled and is set to required to remove the optional mark
            >
              <InputWithCheckmarkValidation
                onChange={() => {}}
                isValid={true}
                appearance={'largeInput'}
                isDisabled={true}
                testId="country"
                placeholder={translateText(
                  `corporateAccounts.countries.${countryCode.toLowerCase()}`,
                )}
              />
            </Item>
          </Col>
        </Row>
        <Row gutter={formItemSize}>
          <Col
            span={formItemSize}
            onFocus={() =>
              sendAmplitudeEvent(
                AMPLITUDE_EVENT_TYPES.selfRegistrationCorporateClientRegistrationStreetAddressFieldClicked,
              )
            }
          >
            <Item
              name={'address1'}
              label={translateText('corporateAccounts.corporateClient.streetAddress')}
              rules={[
                {
                  required: true,
                  message: translateText(
                    'corporateAccounts.corporateClient.address.error.presence',
                  ),
                },
                MAXIMUM_CHAR_COUNT({ message: characterCountExceededMessage }),
              ]}
            >
              <InputWithCheckmarkValidation
                onChange={() => handleFieldChange('address1', setIsAddress1Valid)}
                isValid={isAddress1Valid}
                appearance={'largeInput'}
                testId="address1"
              />
            </Item>
          </Col>
        </Row>
        <Row gutter={formItemSize}>
          <Col
            span={formItemSize}
            onFocus={() =>
              sendAmplitudeEvent(
                AMPLITUDE_EVENT_TYPES.selfRegistrationCorporateClientRegistrationOfficeNumFieldClicked,
              )
            }
          >
            <Item
              name={'address2'}
              label={translateText('corporateAccounts.corporateClient.officeNumber')}
              data-testid="officeNumber"
              required={false}
              rules={[
                { required: false },
                MAXIMUM_CHAR_COUNT({ message: characterCountExceededMessage }),
              ]}
            >
              <InputWithCheckmarkValidation
                onChange={() => handleFieldChange('address1', setIsAddress2Valid)}
                isValid={isAddress2Valid}
                appearance={'largeInput'}
                testId="address2"
              />
            </Item>
          </Col>
        </Row>
        {JURISDICTION_REQUIRED_COUNTRIES.includes(countryCode) && (
          <Row gutter={formItemSize}>
            <Col span={formItemSize}>
              <Item
                name={'jurisdiction'}
                label={getJurisdictionLabel(countryCode, translateText)}
                rules={[
                  {
                    required: JURISDICTION_REQUIRED_COUNTRIES.includes(countryCode),
                    message: translateText(
                      'corporateAccounts.corporateClient.region.error.presence',
                    ),
                  },
                ]}
              >
                <Select
                  className={styles.dropDownContainer}
                  placeholder={translateText(
                    'corporateAccounts.corporateClient.region.placeholder',
                  )}
                  data-testid="jurisdiction"
                  onChange={() =>
                    sendAmplitudeEvent(
                      AMPLITUDE_EVENT_TYPES.selfRegistrationCorporateClientRegistrationRegionFieldClicked,
                    )
                  }
                >
                  {REGION_CODES[countryCode] &&
                    Object.keys(REGION_CODES[countryCode]).map((_region, index) => (
                      <Option
                        key={REGION_CODES[countryCode][index].value}
                        value={REGION_CODES[countryCode][index].value}
                      >
                        {REGION_CODES[countryCode][index].label}
                      </Option>
                    ))}
                </Select>
              </Item>
            </Col>
          </Row>
        )}
        <Row gutter={formItemSize}>
          <Col
            span={responsiveColSpan}
            onFocus={() =>
              sendAmplitudeEvent(
                AMPLITUDE_EVENT_TYPES.selfRegistrationCorporateClientRegistrationCityFieldClicked,
              )
            }
          >
            <Item
              name={'city'}
              label={translateText('corporateAccounts.corporateClient.city')}
              rules={[
                {
                  required: true,
                  message: translateText('corporateAccounts.corporateClient.city.error.presence'),
                },
                MAXIMUM_CHAR_COUNT({ message: characterCountExceededMessage }),
              ]}
            >
              <InputWithCheckmarkValidation
                onChange={() => handleFieldChange('city', setIsCityValid)}
                isValid={isCityValid}
                appearance={'largeInput'}
                testId="city"
              />
            </Item>
          </Col>
          <Col
            span={responsiveColSpan}
            onFocus={() =>
              sendAmplitudeEvent(
                AMPLITUDE_EVENT_TYPES.selfRegistrationCorporateClientRegistrationPostalFieldClicked,
              )
            }
          >
            <Item
              name={'postalCode'}
              label={translateText('corporateAccounts.corporateClient.postalCode')}
              rules={[
                {
                  required: true,
                  message: translateText(
                    'corporateAccounts.corporateClient.postalCode.error.presence',
                  ),
                },
                MAXIMUM_CHAR_COUNT({ message: characterCountExceededMessage }),
                {
                  validator: postalCodeValidator(countryCode),
                  message: translateText(
                    'corporateAccounts.corporateClient.postalCode.error.validity',
                  ),
                },
              ]}
            >
              <InputWithCheckmarkValidation
                onChange={() => handleFieldChange('postalCode', setIsPostalCodeValid)}
                isValid={isPostalCodeValid}
                appearance={'largeInput'}
                testId="postalCode"
              />
            </Item>
          </Col>
        </Row>
        <Row gutter={formItemSize}>
          <Col
            span={formItemSize}
            onFocus={() =>
              sendAmplitudeEvent(
                AMPLITUDE_EVENT_TYPES.selfRegistrationCorporateClientRegistrationTaxFieldClicked,
              )
            }
          >
            <Item
              name="taxNumber"
              label={taxNumberLabel}
              rules={[
                {
                  required: isTaxNumberRequired,
                  message: translateText(
                    'corporateAccounts.corporateClient.taxNumber.error_presence',
                    {
                      taxNumber: taxNumberLabel,
                    },
                  ),
                },
                {
                  validator: (_, value) =>
                    value ? validateTaxNumber(value, translateText) : Promise.resolve(),
                },
              ]}
            >
              <InputWithCheckmarkValidation
                onChange={() => handleFieldChange('taxNumber', setIsTaxNumberValid)}
                isValid={isTaxNumberValid}
                appearance={'largeInput'}
                testId="taxNumber"
              />
            </Item>
          </Col>
        </Row>
        {
          <Row gutter={formItemSize}>
            <Col span={formItemSize} className={styles.saveButton}>
              <Button
                type="primary"
                size="large"
                data-testid="saveButton"
                onClick={() => handleSubmit()}
                loading={isLoading}
                disabled={!isFormValid}
              >
                {translateText('corporateAccounts.actions.save')}
              </Button>
            </Col>
          </Row>
        }
      </Form>
    </div>
  );
};

export default injectIntl(CorporateClientRegistrationFormV2);
