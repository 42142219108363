import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useTranslation from 'utils/hooks/useTranslation';
import Modal from 'components/Shared/Modal';
import DriverImportModalFooter from './DriverImportModalFooter';
import DriverImportModalContent from './DriverImportModalContent';
import DriverImportModalLoading from './DriverImportModalLoading';
import useDriverImportModal from './useDriverImportModal';
import DriverImportModalFailed from './DriverImportModalFailed';

const DriverImportModal = ({ visible, country, onExport, onClose }) => {
  const { translateText } = useTranslation();
  const {
    loading,
    fileList,
    error,
    isError,
    isSuccess,
    validationErrors,
    onFileUpload,
    onImport,
    onReset,
    invitationMethodEmail,
    invitationMethodSms,
    setInvitationMethodSms,
    setInvitationMethodEmail,
  } = useDriverImportModal();
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const adminRole = useSelector(state => state.user?.adminRole);

  const generateToken = token => {
    setRecaptchaToken(token);
  };

  const handleClose = useCallback(() => {
    onClose();
    onReset();
    setRecaptchaToken(null);
  }, [onClose, onReset]);

  const isImportDisabled =
    (!adminRole && invitationMethodEmail === false && invitationMethodSms === false) ||
    !fileList.length ||
    isError ||
    !recaptchaToken;

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess, handleClose]);

  return (
    <Modal
      title={translateText('corporateAccounts.drivers.importModal.title')}
      width={500}
      visible={visible}
      centered
      closable
      footer={
        loading ? null : (
          <DriverImportModalFooter
            loading={loading}
            importDisabled={isImportDisabled}
            onClose={handleClose}
            onConfirm={() => onImport(recaptchaToken)}
            validationErrors={validationErrors}
          />
        )
      }
      onCancel={handleClose}
      destroyOnClose
    >
      {loading ? (
        <DriverImportModalLoading />
      ) : (
        <DriverImportModalContent
          fileList={fileList}
          country={country}
          onExport={onExport}
          onFileUpload={onFileUpload}
          setInvitationMethodEmail={setInvitationMethodEmail}
          setInvitationMethodSms={setInvitationMethodSms}
          invitationMethodEmail={invitationMethodEmail}
          invitationMethodSms={invitationMethodSms}
          generateToken={generateToken}
          setRecaptchaToken={setRecaptchaToken}
        />
      )}
      {isError && !loading && <DriverImportModalFailed error={error} />}
    </Modal>
  );
};

export default React.memo(DriverImportModal);
