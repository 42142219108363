import React from 'react';
import useTranslation from 'utils/hooks/useTranslation';
import tableColumns from './tableColumns';
import { EmptyIconLarge } from 'components/Shared/Icons';
import { injectIntl } from 'react-intl';
import OffsetPaginationTable from 'components/Shared/OffsetPaginationTable';
import Spinner from 'components/Shared/Spinner';

import styles from './style.module.scss';

const PAGE_SIZE = 20;

const InvoiceTable = ({
  handleInvoiceModalVisibility,
  tableData,
  selectedDateRange,
  isInvoiceSummaryListLoadingSuccessful,
}) => {
  const { translateText } = useTranslation();

  const isTableVisible = tableData.length > 0;
  const isDateRangeSelected = selectedDateRange?.startDate && selectedDateRange?.endDate;
  const isNoResults = isDateRangeSelected && isInvoiceSummaryListLoadingSuccessful;

  return isTableVisible ? (
    <OffsetPaginationTable
      loading={false}
      rowKey="invoiceId"
      columns={tableColumns({ handleInvoiceModalVisibility, translateText })}
      dataSource={tableData}
      scroll={{ x: true }}
      pagination={{ defaultPageSize: PAGE_SIZE, showSizeChanger: true }}
    />
  ) : isNoResults ? (
    <div className={styles.emptyState}>
      <EmptyIconLarge />
      {translateText('corporateAccounts.reports.transactions.noResults')}
    </div>
  ) : isDateRangeSelected ? (
    <div className={styles.spinner}>
      <Spinner size={48} />
    </div>
  ) : (
    <div className={styles.emptyState}>
      <EmptyIconLarge />
      {translateText('corporateAccounts.reports.transactions.chooseDateRange')}
    </div>
  );
};

export default injectIntl(InvoiceTable);
